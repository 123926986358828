import React from 'react';
import { useSelector } from 'react-redux';

const Header = () => {
    const profile = useSelector((state)=> state.auth.profile);

    return (
        <div style={{ borderBottom: '1px solid #090f1e0d', paddingBottom: 50 }}>
            <div style={{ textAlign: 'center' }}>
                <h3 style={{
                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                    fontSize: 52,
                    fontWeight: 900,
                    color: '#090f1e',
                    lineHeight: 1.1,
                    padding: 0,
                    margin: 0,
                }}>Бонус <span style={{
                    color: '#ff9815',
                    backgroundImage: 'linear-gradient(99.83deg, #fdc63d -48.63%, #ff9815 97.54%)',
                    backgroundClip: 'text'
                }}>{profile?.bonus?.balance_bonus} {profile?.currency}</span><br /></h3>
            </div>
        </div>
    )
}

export default Header;