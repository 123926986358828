import React from 'react';
import { Typography } from '@mui/material';
import Footer from '../footer';
import {translate} from '../../dictionary';

const Rules = ({mobile}) => {
    return (
        <div>
        <div style={{maxWidth: 1140, width: 'calc(100% - 40px)', margin: '0 auto 20px', color: '#fff', wordBreak: 'break-all', padding: '0 20px'}}>
            <h3>{translate("rules.agreement.title")}</h3>
            <br /><br />
            <Typography>
                {translate('rules.agreement.description')}
            </Typography>
            <br /><br />

            <h3>{translate('rules.title')}</h3>
            <br /><br />
            <Typography>
                {translate('rules.description1')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description2')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description3')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description4')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description5')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description6')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description7')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description8')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description9')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description10')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description11')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description12')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description13')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description14')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description15')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description16')}
            </Typography>
            <br /><br />
            <Typography>
                {translate('rules.description17')}
            </Typography>
            <br /><br />
            <h3>{translate('rules.withdrawals.title')}</h3>
            <br /><br />
            <Typography>
                {translate('rules.withdrawals.description')}
            </Typography>

        </div>
        {
            !mobile && <Footer/>
        }
        </div>
    )
}

export default Rules;