/* eslint-disable */
import {types} from './types';

const initialState = {
    authorize: false,
    profile: {
        
    },
    groups: {
        amount: 0
    },
    count: 0,
}

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_AUTHORIZE:
            return { ...state, authorize: action.payload};
        case types.SET_PROFILE:
            return { ...state, profile: action.payload};
        default:
            return state
    }
}