import { Slide } from '@mui/material';
import React from 'react';

const GroupButton = ({setStep, step}) => {
    const containerRef = React.useRef(null);

    return (
        <div style={{ position: 'absolute', bottom: 0, right: 0, display: 'flex', columnGap: 12 }}>

            <Slide in={step > 1} container={containerRef.current} direction="right">
                <div
                    style={{ display: 'flex', justifyContent: 'center', }}
                    onClick={() => {
                        setStep(value => value - 1);
                    }}
                >
                    <div style={{
                        backgroundImage: 'linear-gradient(99.83deg,#fdc63d -48.63%,#ff9815 97.54%)',
                        boxShadow: '0 12px 24px #ffa62159',
                        borderRadius: 10,
                        padding: '0 35px',
                        height: 50,
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: 1.25,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: '-apple-system,SF Pro Text,sans-serif',
                        fontSize: 16,
                        color: '#FFFFFF',
                        cursor: 'pointer'
                    }}>
                        <span>Предыдущий шаг</span>
                    </div>
                </div>
            </Slide>

            <Slide in={step < 3} container={containerRef.current} direction="left">
                <div
                    style={{ display: 'flex', justifyContent: 'center', }}
                    onClick={() => {
                        setStep(value => value + 1);
                    }}
                >
                    <div style={{
                        backgroundImage: 'linear-gradient(99.83deg,#fdc63d -48.63%,#ff9815 97.54%)',
                        boxShadow: '0 12px 24px #ffa62159',
                        borderRadius: 10,
                        padding: '0 35px',
                        height: 50,
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: 1.25,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: '-apple-system,SF Pro Text,sans-serif',
                        fontSize: 16,
                        color: '#FFFFFF',
                        cursor: 'pointer'
                    }}>
                        <span>Следующий шаг</span>
                    </div>
                </div>
            </Slide>
        </div>
    )
}

export default GroupButton