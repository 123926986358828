/* eslint-disable */
import { getCard } from '../api/getCard';
import { setGetCardAction } from '../action';

export const setGetCard = (params) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await getCard(params);

            dispatch(setGetCardAction(data))
        } catch (error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}