/* eslint-disable */
import axios from 'axios';
import {backendHost} from '../../config';

export const getJackpotList = (params) => {
    return axios.get(`${backendHost}/api/client/jackpot/list`, {
      params,
      headers: {
        Authorization: localStorage.getItem('token')
      }
    });
};