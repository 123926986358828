/* eslint-disable */
import { types } from './types';

const initialState = {
    list: {
        list: [],
        page: 0
    },
    search: {
        list: [],
        page: 0
    },
    provider: []
}

export function gamesReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GAMES_LIST:
            return { ...state, list: action.payload };
        case types.SET_SEARCH_GAMES_LIST:
            return { ...state, search: action.payload };
        case types.SET_GAMES:
            return { ...state, list: action.payload };
        case types.SET_GAME_ID:
            return { ...state, gameId: action.payload };
        case types.SET_LIST_LAST_WIN:
            return { ...state, listlast: action.payload };
        case types.SET_LAST_TOURNAMENT:
            return { ...state, lastTournament: action.payload };
        case types.SET_JACKPOT:
            return { ...state, list_jackpot: action.payload };
        case types.SET_LIST_PROVIDER:
            return { ...state, list_provider: action.payload };
        case types.SET_NOTIFICATION:
            return { ...state, notification: action.payload };
        case types.SET_GAME:
            return { ...state, game: action.payload };
        case types.SET_PROVIDER:
            return { ...state, provider: action.payload};
        default:
            return state
    }
}