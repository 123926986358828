/* eslint-disable */
import { setGetProviderListAction } from '../action';
import { getProviderList } from '../api/getProviderList';

export const setGetProviderList = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getProviderList(params);

            if (data.status === 'success') {
              const provider = data.data?.sort((a, b) => a.provider > b.provider ? 1 : b.provider > a.provider ? -1 : 0);

              dispatch(setGetProviderListAction(provider))
            }
        } catch(error) {
            console.error(error)
        }
    }
}