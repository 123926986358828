/* eslint-disable */
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Coin } from '../assets/coin.svg';
import { ReactComponent as User } from '../assets/user.svg';
import { ReactComponent as Wager } from '../assets/wager.svg';
import { setGetAuthorization } from '../../store/authorization/thunk/setGetAuthorization';
import { useRouter } from '../../components/hook/useRouter';
import AuthDesktop from '../auth';
import Replenishment from '../replenishment';
import Withdrawal from '../withdrawal';
import { translate } from '../../dictionary';
import ReactFlagsSelect from "react-flags-select";
import ModalBonus from './ModalBonus';

const Header = ({ isOpen, setIsOpen, isOpenReplenishment, setIsOpenReplenishment }) => {
  const [isActive, setIsActive] = React.useState('home');

  const [isOpenWithdrawal, setIsOpenWithdrawal] = React.useState(false);
  const [isShowModalBonus, setIsShowModalBonus] = React.useState(false);

  const router = useRouter();
  const { pathname, location } = router;

  const navigation = (value) => router.history.push(value);

  const dispatch = useDispatch();
  const profile = useSelector((store) => store.auth?.profile);

  const token = localStorage.getItem('token');

  React.useEffect(() => {
    if (token && token !== 'null') {
      dispatch(setGetAuthorization(1))
    }
  }, [token])

  const onSelect = (language) => {
    const select_language = language?.toLowerCase() === 'us' ? 'en' : language?.toLowerCase() === 'ru' ? 'ru' : 'kz';
    localStorage.setItem('language', select_language);
    window.location.replace(window.location.origin);
  }

  return (
    <div >
      <div style={{ background: 'rgb(32, 37, 59)' }}>
        <div style={{ width: 'calc(100% - 40px)', height: 70, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', padding: '0 20px', position: 'relative' }}>

          <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
            <Typography style={{ fontFamily: "Nosifer, sans-serif", fontSize: 24, color: '#fff' }}>Lakas</Typography>
            <div style={{ marginLeft: 50 }}>
              <ReactFlagsSelect
                selected={localStorage.getItem('language')?.toLowerCase() === 'en' ? 'US' : localStorage.getItem('language')?.toLowerCase() === 'ru' ? "RU" : "KZ"}
                onSelect={onSelect}
                countries={["RU", "KZ", "US"]}
                placeholder="Select language"
                showSelectedLabel={false}
                showSecondarySelectedLabel={false}
                showSecondaryOptionLabel={false}
                selectedSize={40}
                fullWidth={true}
                style={{
                  borderColor: '#fff'
                }}
              />
            </div>
            {
              localStorage.getItem('token') && (
                <div style={{ maxWidth: 1140, width: '100%', margin: '0 auto', display: 'flex', justifyContent: 'flex-end', columnGap: 12 }}>
                  {
                    profile?.groups_cashiers === 'all' && (
                      <div style={{ display: 'flex', columnGap: 12 }}>
                        <div
                          onClick={() => setIsOpenReplenishment(true)}
                          style={{ background: '#F8BA16', padding: '5px 10px', display: 'flex', alignItems: 'center', columnGap: 12, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 4, cursor: 'pointer' }}>
                          <Typography style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#000' }}>
                            {translate('replenishment.btn')}
                          </Typography>
                        </div>
                        <div
                          onClick={() => setIsOpenWithdrawal(true)}
                          style={{ background: '#fc4e03', padding: '5px 10px', display: 'flex', alignItems: 'center', columnGap: 12, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 4, cursor: 'pointer' }}>
                          <Typography style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#fff' }}>
                            {translate('withdrawals.btn2')}
                          </Typography>
                        </div>
                      </div>
                    )
                  }
                  <div style={{ background: '#303753', padding: '5px 10px', display: 'flex', alignItems: 'center', columnGap: 12, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 4 }}>
                    <Coin style={{ width: 30, height: 30, fill: "#F7BB18" }} />
                    <Typography style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#fff' }}>
                      {new Intl.NumberFormat('ru-RU').format(profile?.balance?.toFixed(2) || 0) + ` ${profile?.currency || ''}`}
                    </Typography>
                  </div>
                  {
                    profile?.bonus?.id && (
                      <div>
                        <div style={{ display: 'flex', justifyContent: 'center'}} onClick={() => setIsShowModalBonus(true)}>
                          <div style={{
                            backgroundImage: 'linear-gradient(99.83deg,#fdc63d -48.63%,#ff9815 97.54%)',
                            boxShadow: '0 12px 24px #ffa62159',
                            borderRadius: 10,
                            padding: '0 35px',
                            height: 40,
                            fontWeight: 700,
                            fontSize: 14,
                            lineHeight: 1.25,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: '-apple-system,SF Pro Text,sans-serif',
                            fontSize: 16,
                            color: '#FFFFFF',
                            cursor: 'pointer'
                          }}>
                            <span>Бонус {new Intl.NumberFormat('ru-RU').format(profile?.bonus?.balance_bonus?.toFixed(2) || 0) + ` ${profile?.currency || ''}`}</span>
                          </div>
                        </div>
                      </div>
                    )
                  }

                  {
                    Boolean(profile?.wager && profile?.wager > 0) && (
                      <div style={{ background: '#303753', padding: '5px 10px', display: 'flex', alignItems: 'center', columnGap: 12, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 4 }}>
                        <Wager style={{ width: 25, height: 25, fill: "#BB2047" }} />
                        <Typography style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#fff' }}>
                          {new Intl.NumberFormat('ru-RU').format(profile?.wager?.toFixed(2) || 0) + ' wager'}
                        </Typography>
                      </div>
                    )
                  }
                  <div style={{ background: '#303753', padding: '5px 10px', display: 'flex', alignItems: 'center', columnGap: 12, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 4 }}>
                    <User style={{ width: 25, height: 25, fill: "#F7BB18" }} />
                    <Typography style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#fff' }}>
                      {profile?.login}
                    </Typography>
                  </div>
                  <div onClick={() => {
                    localStorage.removeItem('token')
                    window.location.replace(window.location.origin)
                  }} style={{ background: '#303753', padding: '5px 10px', display: 'flex', alignItems: 'center', borderRadius: 4, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', cursor: 'pointer' }}>
                    <Typography style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#757B96', cursor: 'pointer' }}>{translate('common.exit')}</Typography>
                  </div>
                </div>
              )
            }
          </div>
        </div>
        <div style={{ position: 'absolute', top: 15, left: 'calc(50% - 150px)' }}>
          {
            !localStorage.getItem('token') && (
              <Button
                variant="contained"
                onClick={() => setIsOpen(true)}
                style={{ width: 300 }}
              >
                {translate('common.signin')}
              </Button>
            )
          }

        </div>

      </div>
      <div style={{ marginTop: 12, display: 'flex', justifyContent: 'center' }}>
        <Typography
          style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, padding: '0 10px 10px', cursor: 'pointer', color: pathname === '/' && !location.hash ? '#FFFFFF' : '#757B96', borderBottom: pathname === '/' && !location?.hash ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
          onClick={() => {
            setIsActive('home');
            navigation('/')
          }}
        >
          {translate('navigation.home_little')}
        </Typography>
        {
          localStorage.getItem('token') && (
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: pathname === '/account/referral' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: pathname === '/account/referral' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                setIsActive('account');
                navigation('/account/referral');
              }}
            >
              {translate('navigation.account')}
            </Typography>
          )
        }
      </div>
      <AuthDesktop isOpen={isOpen} setIsOpen={setIsOpen} />
      <Replenishment setIsOpenReplenishment={setIsOpenReplenishment} isOpenReplenishment={isOpenReplenishment} />
      <Withdrawal setIsOpenWithdrawal={setIsOpenWithdrawal} isOpenWithdrawal={isOpenWithdrawal} profile={profile} />
      <ModalBonus isOpen={isShowModalBonus} onClose={setIsShowModalBonus} />
    </div>
  )
}

export default Header;