/* eslint-disable */
import {types} from './types';

const initialState = {
    profile: {},
    list: {
        list: []
    }
}

export function referralReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CHECK_REFERRAL:
            return { ...state, profile: action.payload};
        case types.GET_LIST_REFERRAL:
            return { ...state, list: action.payload};
        default:
            return state
    }
}