/* eslint-disable */
import axios from 'axios';
import { backendHost } from '../../config';

export const postGameInit = (params) => {
    return axios.post(`${backendHost}/api/game/init`, params, {
        headers: {
            Authorization: localStorage.getItem('token')
        }
    });
};