/* eslint-disable */
import axios from 'axios';
import {backendHost} from '../../config';

export const postChangePassword = (params) => {
    return axios.post(`${backendHost}/api/client/change-password`, params, {
        headers: {
            Authorization: localStorage.getItem('token')
        }
    });
};