/* eslint-disable */
import {getTransaction} from '../api/getTransaction';
import {setGetTransactionAction} from '../action';

export const setGetTransaction = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getTransaction(params);
            const list = getState().transaction.list;
            const newTemp = {
                transaction: [...list.transaction, ...data.data.transaction],
                count: data.data.count
            }

            if (data.status === 'success') {
                dispatch(setGetTransactionAction(newTemp))
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}