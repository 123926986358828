
/* eslint-disable */
import axios from 'axios';
import {backendHost} from '../../config';

export const getReplenishmentTransaction = (params) => {
    return axios.get(`${backendHost}/api/client/replenishment/transaction`, {
      params,
      headers: {
        Authorization: localStorage.getItem('token')
      }
    });
};