import {postConfirmationPhone} from '../api/postConfirmationPhone';
import { setPostRegistration } from './setPostRegistration';

export const setPostConfirmationPhone = (params, setShowModalConfirm, setError) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postConfirmationPhone(params);
            const paramsRegistration = {
                login: params.login,
                password: params.password,
                type: 'phone',
                promocode: params.promocode,
                currency: 'RUB',
                code: '7',
                activeBonus: ''
            }

            paramsRegistration.utm_source = localStorage.getItem('utm_source');
            paramsRegistration.utm_term = localStorage.getItem('utm_term');
            paramsRegistration.utm_medium = localStorage.getItem('utm_medium');
            paramsRegistration.utm_campaign = localStorage.getItem('utm_campaign');
            paramsRegistration.utm_content = localStorage.getItem('utm_content');
            
            dispatch(setPostRegistration(paramsRegistration));

        } catch(error) {
            console.log(error);
            // setError(true)
        }
    }
}