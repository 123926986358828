/* eslint-disable */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Banner = () => {
  const [indexActive, setIndexActive] = React.useState(0);
  const language = localStorage.getItem('language');

  return (
    <Carousel
          autoPlay={true}
          infiniteLoop
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          selectedItem={indexActive}
          emulateTouch
          interval={2000}
          onChange={(v) => setIndexActive(v)}
        >
        <img src='assets/banner/leto.png' alt="" style={{width: 340, borderRadius: 12, boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.8)"}}/>
        <img src='assets/banner/four1.jpg' alt="" style={{width: 340, borderRadius: 12, boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.8)"}}/>
        <img src='assets/banner/two1.jpg' alt="" style={{width: 340, borderRadius: 12, boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.8)"}}/>
        
      </Carousel>
  )
}

export default Banner;