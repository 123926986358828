/* eslint-disable */
import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { rootReducer } from './reducer';
import thunk from 'redux-thunk';

const initialState = {};

export const history = createBrowserHistory();

const browserRouterMiddleware = routerMiddleware(history);

const middleware = [browserRouterMiddleware, thunk];

const store = createStore(
    rootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware)),
);

export {
    store
}