/* eslint-disable */
import {types} from './types';

export const setAuthorizeAction = (payload) => {
    return {
        type: types.SET_AUTHORIZE,
        payload,
      }
}

export const setProfileAction = (payload) => {
  return {
      type: types.SET_PROFILE,
      payload,
    }
}