/* eslint-disable */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { ReactComponent as Back } from '../../../desktop/assets/back.svg';
import { Typography } from '@mui/material';
import { useRouter } from '../../../components/hook/useRouter';
import { setPostPromocode } from '../../../store/bonuses/thunk/setPostPromocode';
import { useDispatch, useSelector } from 'react-redux';
import Modals from './fs/modals';
import FreespinStepOne from './fs/step1';
import FreespinStepTwo from './fs/step2';
import FreespinStepThree from './fs/step3';
import FreespinStepFour from './fs/step4';
import { translate } from '../../../dictionary';

const Bonus = () => {
  const [indexActive, setIndexActive] = React.useState(0);
  const router = useRouter();
  let navigate = (value) => router.history.push(value);
  const [promocode, setPromocode] = React.useState('');
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [messageModal, setMessageModal] = React.useState('');

  const profile = useSelector(state => state.auth.profile);


  return (
    <div style={{ margin: 20, position: 'relative' }}>
      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: -35, right: 10 }} onClick={() => navigate('/user')}>
        <Back style={{ width: 30, height: 30, fill: '#fff' }} />
        <Typography style={{ color: '#fff' }}>{translate('common.back')}</Typography>
      </div>
      {
            profile?.login && profile?.connection_groups !== 'true' && (
              <div style={{display: 'flex', alignItems: 'center',columnGap: 12, position: 'relative', marginBottom: 12}}>
                <input type="text" value={promocode} onChange={(e) => setPromocode(e.target.value)} style={{padding: '5px 10px', outline: 'none', width: '100%', fontSize: 16, textTransform: 'uppercase', height: 30, fontFamily: "Montserrat, sans-serif",}} placeholder={translate('referral.placeholder.input_promocode')}/>
                <div 
                  onClick={() => dispatch(setPostPromocode({promocode}, setShowModal, setMessageModal))}
                  style={{display: 'flex', width: 35, height: 35, background: '#2F2FDD', borderRadius: 8, justifyContent: 'center', position: 'absolute', top: 5, right: 5, cursor: 'pointer'}}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" fill="#fff">
                        <path d="M19.414 27.414l10-10c0.781-0.781 0.781-2.047 0-2.828l-10-10c-0.781-0.781-2.047-0.781-2.828 0s-0.781 2.047 0 2.828l6.586 6.586h-19.172c-1.105 0-2 0.895-2 2s0.895 2 2 2h19.172l-6.586 6.586c-0.39 0.39-0.586 0.902-0.586 1.414s0.195 1.024 0.586 1.414c0.781 0.781 2.047 0.781 2.828 0z"></path>
                    </svg>
                  </div>
                </div>
            )
        }
      <div style={{textAlign: 'center'}}>
        <Typography style={{ color: '#fff',  }}>
          Активные бонусы
        </Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {
          Number(profile?.fs?.quantity) > 0 && profile?.fs?.status && (
            <FreespinStepOne fs={profile?.fs} />
          )
        }
        {
          Number(profile?.fs?.quantity) === 0 && profile?.fs?.status && profile?.fs?.bonus_balance && profile?.fs?.win_amount > 0 && (
            <FreespinStepTwo fs={profile?.fs} />
          )
        }
        {
          profile?.fs_played?.status && profile?.fs_played?.wager_played < profile?.fs_played?.wager && Number(profile?.fs_played?.date_from) + 259200000 >= new Date().getTime() && (
            <FreespinStepThree fs={profile?.fs_played} />
          )
        }
        {
          profile?.fs_played?.status && profile?.fs_played?.wager_played >= profile?.fs_played?.wager && Number(profile?.fs_played?.date_from) + 259200000 >= new Date().getTime() && (
            <FreespinStepFour fs={profile?.fs_played} />
          )
        }
      </div>

      {
        showModal && <Modals message={messageModal} setShowModal={setShowModal} />
      }
    </div>
  )
}

export default Bonus;