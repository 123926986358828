/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';
import { Dialog, Typography } from '@mui/material';
import Rules from '../../../../desktop/rules';
import { ReactComponent as Whatsapp } from '../../../../components/img/whatsapp.svg';
import { translate } from '../../../../dictionary';
import ReactFlagsSelect from "react-flags-select";
import ModalBonus from './ModalBonus';

const Navigation = ({ profile, setIsOpenReplenishment, setIsOpenWithdrawal, setIsShowModalBonus }) => {
  const [isOpenRules, setIsOpenRules] = React.useState(false);


  const onSelect = (language) => {
    const select_language = language?.toLowerCase() === 'us' ? 'en' : language?.toLowerCase() === 'ru' ? 'ru' : 'kz';
    localStorage.setItem('language', select_language);
    window.location.replace(window.location.origin);
  }

  return (
    <div>
      {
        profile?.bonus?.id && (
          <div style={{ width: 'calc(100% - 40px)', padding: '0 20px', marginBottom: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }} onClick={() => setIsShowModalBonus(true)}>
              <div style={{
                backgroundImage: 'linear-gradient(99.83deg,#fdc63d -48.63%,#ff9815 97.54%)',
                boxShadow: '0 12px 24px #ffa62159',
                borderRadius: 10,
                padding: '0 35px',
                height: 40,
                fontWeight: 700,
                fontSize: 14,
                lineHeight: 1.25,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                fontSize: 16,
                color: '#FFFFFF',
                cursor: 'pointer',
                width: '100%',
              }}>
                <span>Бонус {new Intl.NumberFormat('ru-RU').format(profile?.bonus?.balance_bonus?.toFixed(2) || 0) + ` ${profile?.currency || ''}`}</span>
              </div>
            </div>
          </div>
        )
      }

      {
        profile?.groups_cashiers === 'all' && (
          <div style={{ display: 'flex', columnGap: 12, padding: '0 20px' }}>
            <div
              onClick={() => setIsOpenReplenishment(true)}
              style={{ width: '100%', display: 'flex', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', background: 'rgb(207, 14, 126)', padding: '5px 10px', display: 'flex', alignItems: 'center', columnGap: 12, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 4, cursor: 'pointer' }}>
              <Typography style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#fff' }}>
                {translate('replenishment.btn')}
              </Typography>
            </div>
            <div
              onClick={() => setIsOpenWithdrawal(true)}
              style={{ background: '#fc4e03', padding: '5px 10px', display: 'flex', alignItems: 'center', columnGap: 12, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 4, cursor: 'pointer' }}>
              <Typography style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#fff' }}>
                {translate('withdrawals.btn2')}
              </Typography>
            </div>
          </div>
        )
      }
      <div style={{ margin: '10px 20px' }}>
        <ReactFlagsSelect
          selected={localStorage.getItem('language')?.toLowerCase() === 'en' ? 'US' : localStorage.getItem('language')?.toLowerCase() === 'ru' ? "RU" : "KZ"}
          onSelect={onSelect}
          countries={["RU", "KZ", "US"]}
          placeholder="Select language"
          showSelectedLabel={false}
          showSecondarySelectedLabel={false}
          showSecondaryOptionLabel={false}
          selectedSize={20}
          fullWidth={true}
          style={{
            borderColor: '#fff'
          }}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'Montserrat, sans-serif', color: '#ffffff', fontWeight: 500, fontSize: 18, boxShadow: '4px 4px 21px 0px rgba(0, 144, 255, 0.2)' }} className='games-root'>
        <Link to="/" style={{ padding: '5px 0 10px 5px', color: '#fff', textDecoration: 'none' }}>
          <span>{translate('navigation.home')}</span>
        </Link>
        <Link to="/user/referral" style={{ padding: '5px 0 10px 5px', color: '#fff', textDecoration: 'none' }}>
          <span>{translate('navigation.referral_program')}</span>
        </Link>

        {/* <Link to="/tournament" style={{ padding: '5px 0 10px 5px', color: '#fff', textDecoration: 'none' }}>
          <span>{translate('navigation.tournament')}</span>
        </Link> */}
        <Link to="/user/bonus" style={{ padding: '5px 0 10px 5px', color: '#fff', textDecoration: 'none' }}>
          <span>{translate('navigation.bonuses')}</span>
        </Link>
        <Link to="/user/statistic" style={{ padding: '5px 0 10px 5px', color: '#fff', textDecoration: 'none' }}>
          <span>{translate('navigation.statistics')}</span>
        </Link>
        <Link to="/user/password" style={{ padding: '5px 0 10px 5px', color: '#fff', textDecoration: 'none' }}>
          <span>{translate('navigation.change_password')}</span>
        </Link>
        <div onClick={() => setIsOpenRules(true)} style={{ padding: '5px 0 10px 5px', color: '#fff', textDecoration: 'none' }}>
          <span>{translate('navigation.rules')}</span>
        </div>
      </div>

      <div style={{ marginBottom: 50, display: 'flex', flexDirection: 'column', fontFamily: 'Montserrat, sans-serif', color: '#ffffff', fontWeight: 500, fontSize: 18, boxShadow: '4px 4px 21px 0px rgba(0, 144, 255, 0.2)' }} className='games-root'>
        <div
          onClick={() => window.location.replace('https://wa.me/79166578649')}
          style={{
            width: "100%",
            display: 'flex',
            alignItems: 'center',
            columnGap: 12,
          }}>
          <Whatsapp
            style={{
              width: 40,
              height: 40,
            }}
          />
          {translate('navigation.online_manager')}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'Montserrat, sans-serif', color: '#ffffff', fontWeight: 500, fontSize: 18, boxShadow: '4px 4px 21px 0px rgba(0, 144, 255, 0.2)' }} className='games-root'>
        <div style={{ padding: '5px 0 10px 5px' }} onClick={() => {
          localStorage.removeItem('token');
          window.location.replace(window.location.origin);
        }}>
          <span>{translate('common.exit')}</span>
        </div>
      </div>
      <Dialog open={isOpenRules} onClose={() => setIsOpenRules(false)}>
        <div style={{ background: 'rgb(27, 32, 54)', position: 'relative' }}>
          <div
            onClick={() => setIsOpenRules(false)}
            style={{ width: 35, height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#f5f5f5', borderRadius: 4, position: 'absolute', right: 0, top: 0 }}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.91517 -8.83304e-06L0 0.915161L9.08108 9.99624L9.99625 9.08107L0.91517 -8.83304e-06Z" fill="#231F20" />
              <path d="M0.000841899 9.08483L0.915771 10.0002L9.99446 0.916782L9.07953 0.00137182L0.000841899 9.08483Z" fill="#231F20" />
            </svg>
          </div>

          <Rules mobile />
        </div>
      </Dialog>
    </div>
  )
}

export default Navigation;