import RU from './ru.json';
import EN from './en.json';
import KZ from './kz.json';

export const translate = (value) => {
    const language = localStorage.getItem('language')?.toLowerCase();

    switch (language) {
        case 'en': {
            if (!EN[value]) {
                return value
            }
            return EN[value]; 
        }
        case "kz": {
            if (!KZ[value]) {
                return value;
            }
            return KZ[value];
        }
        case "ru": {
            if (!RU[value]) {
                return value
            }
            return RU[value]; 
        }
    }
}

export const i18n = (replaceObject, dictionary) => {
    let textTranslate = translate(dictionary);
    for (let key in replaceObject) {
        textTranslate = textTranslate.replace(`{${key}}`, replaceObject[key]);
    }
    return textTranslate;
}