/* eslint-disable */
import { setGetListReferralAction } from "../action";
import { getListReferral } from "../api/getListReferral";

export const setGetListReferral = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getListReferral(params);
            const list = getState().referral.list;
            const newTemp = {
                list: [...list.list, ...data.data.list],
                count: data.data.count,
                page: data.data.page
            }
           
            // if (data.status === 'success') {
                dispatch(setGetListReferralAction(newTemp))
            // }
 
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}