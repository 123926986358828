/* eslint-disable */
import { postCreateReferral } from "../api/postCreateReferral";
import { setGetCheckReferral } from "./setGetCheckReferral";

export const setPostCreateReferral = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postCreateReferral(params);
            dispatch(setGetCheckReferral())
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}