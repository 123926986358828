/* eslint-disable */
import { Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import {translate} from '../../dictionary';

const Footer = () => {

  return (
    <div style={{ width: 'calc(100% - 40px)', height: 70, background: '#20253B', boxShadow: '0px -6px 8px 0px rgba(0, 0, 0, 0.2)', padding: '0 20px', display: 'flex' }}>
      <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
        <Typography style={{ fontFamily: "Nosifer, sans-serif", fontSize: 24, color: '#fff' }}>Lakas</Typography>
      </div>
      <div style={{maxWidth: 1140, width: '100%', margin: '0 auto', paddingTop: 15}}>
        
        <div style={{display: 'flex', columnGap: 12, marginBottom: 8}}>
        <Link to="/rules"  style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#fff', textDecoration: 'none', cursor: 'pointer' }}>{translate('rules.site')}</Link>
      </div>
      <Typography style={{ fontFamily: "Montserrat, sans-serif", fontSize: 14, color: '#fff' }}>{translate('rules.copyright')}</Typography>
      </div>
      
    </div>
  )
}

export default Footer;