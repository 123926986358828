import React from 'react';

const Donut = ({
    percent,
}) => {
    const max = 630;
    const amount = max / 100 * percent;
    
//     <linearGradient id="diagram-gradient-part-0" x1="202" y1="34" x2="34" y2="202" gradientUnits="userSpaceOnUse">
//     <stop offset="0" stop-color="#0DA453">
//     </stop>
//     <stop offset="1" stop-color="#31BC69">
//     </stop>
// </linearGradient>
    return (
        <svg width="250" height="250">
            <defs>
                <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stop-color="#0DA453" />
                    <stop offset="100%" stop-color="#31BC69" />
                </linearGradient>
            </defs>
            <circle cx="125" cy="125" r="100" stroke="#E5E5E5" stroke-width="10" fill="transparent" />
            <path d="M 125 25 A 100 100 90 1 1 125 225 A 100 100 90 1 1 125 25" stroke="url(#linear)" stroke-linecap="round" stroke-width="10" fill="transparent" stroke-dasharray={`${amount},1256`} />
        </svg>
    )
}

export default Donut;