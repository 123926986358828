/* eslint-disable */
import React from 'react';
import ReactCodeInput from 'react-code-input';
import Button from "@mui/material/Button";
import { useForm, useWatch } from 'react-hook-form';
import {setPostConfirmationPhone} from '../../../store/authorization/thunk/setPostConfirmationPhone';
import { useDispatch } from 'react-redux';
import { translate } from '../../../dictionary';

const Confirmation = (props) => {
  const dispatch = useDispatch();

  const { handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      login: '',
      code: ''
    }
  });
   
  const phone = useWatch({
    control: props?.control,
    name: 'login'
  })

  const password = useWatch({
    control: props?.control,
    name: 'password'
  })

  const promocode = useWatch({
    control: props?.control,
    name: 'promocode'
  })

  const onSubmitForm = (store) => {
    const params = {
      login: '7' + String(phone),
      code: store.code
    }
    if (params.code.length === 4) {
      dispatch(setPostConfirmationPhone({
        login: '7' + String(phone),
        promocode,
        password,
        codeSms: store.code,
      }));
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off">
      <div  className='auth-root'>
        <div className='confirmation__title'>
          <span className='confirmation__title__confirm'>{translate('confirm.title')}</span>
        </div>
        <div className='confirmation__code'>
          <div className='confirmation__code__description'>
            <span dangerouslySetInnerHTML={{__html: translate('confirm.send_sms')}}/>
          </div>
          <div className='confirmation__code__phone'>
            <span>+7{phone}</span>
          </div>
          <div className='confirmation__code__code'>
            <span>{translate('confirm.input_field')}</span>
          </div>
          <div className='confirmation__code__input-code'>
            <ReactCodeInput type='text' fields={4} onChange={(v) => setValue('code', v)}/>
          </div>
        </div>
        <div className='confirmation__code__btn'>
          <Button variant="contained" type="submit" >{translate('common.confirm')}</Button>
        </div>
      </div>
    </form>
  )
}

export default Confirmation;