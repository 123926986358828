import React from 'react';
import { ReactComponent as Cross } from './cross.svg';
import { Slide } from '@mui/material';
import Step from './Step';
import Header from './Header';
import Donut from './Donut';
import { useSelector } from 'react-redux';

const ModalBonus = ({
    isOpen,
    onClose
}) => {
    const containerRef = React.useRef(null);
    const profile = useSelector((state) => state.auth.profile);

    return (
        <Slide in={isOpen} container={containerRef.current} direction="up">
            <div style={{ position: 'absolute', top: 0, zIndex: 90000, background: '#FFF', overflow: 'hidden' }} >
                <div style={{
                    background: '#FFFFFF',
                    position: 'relative',
                    top: 0,
                    padding: '60px 30px',
                    overflow: 'hidden',
                    height: '100vh'
                }}>
                    <div onClick={() => {
                        onClose(false);
                    }} style={{
                        cursor: 'pointer',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: 24,
                        height: 24,
                        background: '#edf0f7',
                        borderRadius: 6,
                        position: 'absolute',
                        right: 20,
                        top: 20
                    }}>
                        <Cross style={{ width: 9, height: 9 }} />
                    </div>
                    <Header />
                    <div style={{ marginTop: 50 }}>
                        <div style={{ textAlign: 'center', marginBottom: 50 }}>
                            <h1 style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 36,
                                fontWeight: 900,
                                color: '#090f1e',
                                lineHeight: 1.2,
                                padding: 0,
                                margin: 0,
                            }}>Как забрать бонус?</h1>
                        </div>
                        <Step />
                        <div style={{ minHeight: 250 }}>
                            {
                                profile?.bonus?.id && (
                                    <Slide in={true} container={containerRef.current} direction="left">
                                        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                                            <Donut
                                                percent={100 - (profile?.bonus?.wager_bonus / profile?.bonus?.wager_fixed * 100)}
                                            />
                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
                                                <span style={{
                                                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                                    fontSize: 30,
                                                    fontWeight: 900,
                                                    color: '#090f1e',
                                                    lineHeight: 1.1,
                                                    padding: 0,
                                                    margin: 0,
                                                }}>WAGER</span>
                                                <span style={{
                                                    color: '#ff9815',
                                                    backgroundImage: 'linear-gradient(99.83deg, #fdc63d -48.63%, #ff9815 97.54%)',
                                                    backgroundClip: 'text',
                                                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                                    fontSize: 42,
                                                    fontWeight: 900,
                                                    lineHeight: 1.1,
                                                    padding: 0,
                                                    margin: 0,
                                                }}>{profile?.bonus?.wager_bonus?.toFixed(0)}</span>
                                            </div>
                                        </div>
                                    </Slide>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Slide>
    )
}

export default ModalBonus;