/* eslint-disable */
import {getGamesList} from '../api/getGamesList';
import {setGetGamesListAction} from '../action';

export const setGetGamesList = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getGamesList(params);

            if (data.status === 'success') {
                dispatch(setGetGamesListAction(data.data))
            }
        } catch(error) {
            console.error(error)
        }
    }
}