/* eslint-disable */
import { CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { setGameInit } from '../../store/games/thunk/setGameInit';
import { setGameDemo } from '../../store/games/thunk/setGetGameDemo';
import Footer from '../footer';
import Slot from '../slot';
import {translate} from '../../dictionary';

const Game = () => {
  const dispatch = useDispatch()
  const params = useParams();
  const uuid = params.id;

  React.useEffect(() => {
    if (!localStorage.getItem('token')) {
      dispatch(setGameDemo({
        game_uuid: uuid,
      }))
    } else {
      dispatch(setGameInit({
        game_uuid: uuid,
      }))
    }
    window.scrollTo(0, 0);
  }, [])

  const url = useSelector((state) => state.games.gameId);

  window.addEventListener("message", (e, key) => {
    if (e.data === 'GAME_MODE:CLOSED' || e.data === 'closeFrame') {
      window.location.replace(window.location.origin)
    }
  }, false);

  return (
    <div style={{ height: '100%', background: '#1B2036' }}>
      <div style={{ maxWidth: 1500, margin: '20px auto', marginTop: 20, height: '80%', background: '#1B2036' }}>
        {
          url && (
            <iframe
              id="frame"
              src={url}
              width="100%"
              height="100%"
              frameborder="1"
              frameBorder="none"
              allowFullScreen="true"
              loading="eager"
              style={{
                boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)',
                minHeight: '80vh'
              }}
            >

            </iframe>
          )
        }
        {
          !url && (
            <div style={{
              width: '100%',
              height: '100%',
              minHeight: '80vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              rowGap: 20
            }}>
              <CircularProgress />
              <Typography style={{fontFamily: "Montserrat, sans-serif", color: '#FFFFFF'}}>{translate('common.loading_game')}</Typography>
            </div>
          )
        }

      </div>
      <div>
        <Slot game />
      </div>
      <div style={{ paddingTop: 50, background: '#1B2036' }}>
        <Footer />
      </div>
    </div>
  )
}

export default Game;