/* eslint-disable */
import { Typography, Button } from '@mui/material';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {ReactComponent as Back} from '../../../desktop/assets/back.svg';
import { setPostChangePassword } from '../../../store/authorization/thunk/setPostChangePassword';
import { useRouter } from '../../../components/hook/useRouter';
import {translate} from '../../../dictionary';

const ChangePassword = () => {
  const { register, handleSubmit, control, reset, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      current_password: '',
      new_password: '',
      new_password_repeat: ''
    }
  });

  const dispatch = useDispatch();

  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const onSubmitForm = (store) => {
    const params = {
      currentPassword: store.current_password,
      newPassword: store.new_password,
      newPassword2: store.new_password_repeat
    }

    dispatch(setPostChangePassword(params, setError, setSuccess))
  }

  const current_password = useWatch({
    control,
    name: 'current_password',
  })

  const new_password = useWatch({
    control,
    name: 'new_password',
  });

  const new_password_repeat = useWatch({
    control,
    name: 'new_password_repeat'
  });

  const isValid = current_password && new_password && new_password_repeat && new_password === new_password_repeat && new_password.length >= 6 && new_password_repeat.length >= 6;
  const router = useRouter();
  let navigate = (value) => router.push(value);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off" style={{
      background: 'rgba(255, 255, 255, 0.12)',
      backdropFilter: 'blur(19.5px)',
      padding: '10px',
      margin: '20px',
      borderRadius: '24px',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      rowGap: '10px',
      padding: 20
    }}>
      <div style={{display: 'flex', alignItems: 'center', position: 'absolute', top: -35, right: 10}} onClick={() => navigate('/user')}>
        <Back style={{width: 30, height: 30, fill: '#fff'}}/>
        <Typography style={{color: '#fff'}}>{translate('common.back')}</Typography>
      </div>
      <label htmlFor="currentPassword" style={{width: 'calc(100% - 20px)'}}>
        <Typography color="#FFF">{translate('password.current')}</Typography>
        <input
          {...register('current_password')}
          id="current_password"
          label={translate('password.label')}
          type="password"
          autoComplete="current-password"
          style={{
            border: 'none', 
            background: 'rgba(14, 2, 48, 0.8)',
            outline: 'none',
            borderRadius: 4,
            padding: '5px 10px',
            width: '100%',
            height: 30,
            color: '#fff'
          }}
        />
      </label>
      <label htmlFor="newPassword" style={{width: 'calc(100% - 20px)'}}>
        <Typography color="#FFF">{translate('password.new_password')}</Typography>
        <input
          {...register('new_password')}
          id="new_password"
          label={translate('password.label')}
          type="password"
          autoComplete="new-password"
          style={{
            border: 'none', 
            border: new_password !== new_password_repeat ? '1px solid red' : 'none',
            background: 'rgba(14, 2, 48, 0.8)',
            outline: 'none',
            borderRadius: 4,
            padding: '5px 10px',
            width: '100%',
            height: 30,
            color: '#fff'
          }}
        />
      </label>
      <label htmlFor="new_password_repeat" style={{width: 'calc(100% - 20px)'}}>
        <Typography color="#FFF">{translate('password.repeat_new_password')}</Typography>
        <input
          {...register('new_password_repeat')}
          id="new_password_repeat"
          label={translate('password.label')}
          type="password"
          autoComplete="new_password_repeat"
          style={{
            border: 'none', 
            border: new_password !== new_password_repeat ? '1px solid red' : 'none',
            background: 'rgba(14, 2, 48, 0.8)',
            outline: 'none',
            borderRadius: 4,
            padding: '5px 10px',
            width: '100%',
            height: 30,
            color: '#fff'
          }}
        />
      </label>
      {
        new_password !== new_password_repeat && <Typography color="red">{translate('password.new_mismatch')}</Typography>
      }
      {
        error && <Typography color="#e31010">{translate('password.current_not_correct')}</Typography>
      }
      {
        success && <Typography color="#34eb71">{translate('password.success_change')}</Typography>
      }

      <Typography color="#FFF" style={{fontSize: 12}}>{translate('password.min_length')}</Typography>
      <Button variant="contained" type='onsubmit' style={{width: '100%', marginTop: 20}} disabled={!isValid}>{translate('password.change_btn')}</Button>
    </form>
  )
}

export default ChangePassword;