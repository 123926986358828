/* eslint-disable */
import axios from 'axios';
import {backendHost} from '../../config';

export const getProviderList = (params) => {
  const path = localStorage.getItem('token') ? `${backendHost}/api/v2/games/provider/list` : `${backendHost}/api/provider/list`
    return axios.get(path, {
      params,
      headers: {
        Authorization: localStorage.getItem('token')
      }
    });
};