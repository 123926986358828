import React from 'react';
import Mobile from './mobile';
import Desktop from './desktop/index';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from './components/hook/useRouter';

const App = () => {
    axios.defaults.headers['Organization'] = 1;
    const cookie = readCookie('lakas');
    
    const router = useRouter();
    const {
        query: {
            token
        }
    } = router;
    
    if (token) {
        localStorage.setItem('token', `Bearer ${token}`);
        window.history.pushState(null, document.title, '/');
    }

    const utm_source = router.query?.utm_source;
    const utm_medium = router.query?.utm_medium;
    const utm_campaign = router.query?.utm_campaign;
    const utm_content = router.query?.utm_content;
    const utm_term = router.query?.utm_term;
    const lang = router.query?.lang;
    const current_lang = localStorage.getItem('language');

    if (lang) {
        localStorage.setItem('language', lang);
    }

    if (!current_lang && !lang) {
        localStorage.setItem('language', "ru");
    }

    if (utm_source) {
        localStorage.setItem('utm_source', utm_source)
    }
    if (utm_term) {
        localStorage.setItem('utm_term', utm_term)
    }
    if (utm_medium) {
        localStorage.setItem('utm_medium', utm_medium)
    }
    if (utm_campaign) {
        localStorage.setItem('utm_campaign', utm_campaign)
    }
    if (utm_content) {
        localStorage.setItem('utm_content', utm_content)
        window.history.pushState(null, document.title, '/');
    }

    if (!cookie) {
        const date = new Date;
        date.setDate(date.getDate() + 360);
        const uuid = uuidv4();
        document.cookie = `lakas=${uuid}; path=/; expires=${date.toUTCString()}`;
        axios.defaults.headers['SessionConnect'] = uuid;
    } else {
        axios.defaults.headers['SessionConnect'] = cookie;
    }

    function readCookie(name) {
        const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    return (
        <div style={{
            height: '100%',
            overflow: 'hidden',
            position: 'relative'
        }}>
            {
                (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && <Mobile />
            }
            {
                !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && <Desktop />
            }
        </div>
    )
}

export default App;