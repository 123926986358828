/* eslint-disable */
export const types = {
    SET_GAMES_LIST: '[GAMES] SET GET GAMES',
    SET_SEARCH_GAMES_LIST: '[GAMES] SET SEARCH GAMES LIST',
    SET_GAMES: '[GAMES] SET GAMES',
    SET_GAME_ID: '[GAMES] SET GAME ID',
    SET_LIST_LAST_WIN: '[GAMES] SET LIST LAST WIN',
    SET_LAST_TOURNAMENT: '[GAMES] SET LAST TOURNAMENT',
    SET_JACKPOT: '[GAMES] SET JACKPOT',
    SET_LIST_PROVIDER: '[GAMES] SET LIST PROVIDER',
    SET_NOTIFICATION: '[GAMES] SET NOTIFICATION',
    SET_GAME: '[GAMES] SET GAME',
    SET_PROVIDER: '[GAMES] SET PROVIDER'
}