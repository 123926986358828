/* eslint-disable */
import { setGetJackpotListAction } from "../action";
import { getJackpotList } from "../api/getJackpotList";

export const setGetJackpotList = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getJackpotList(params);
 
            dispatch(setGetJackpotListAction(data.data))
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}