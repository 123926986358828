/* eslint-disable */
import React from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { setGetAuthorization } from '../../../store/authorization/thunk/setGetAuthorization';
import { ReactComponent as Balance } from '../../../desktop/assets/balance.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { useRouter } from '../../../components/hook/useRouter';
import ReactFlagsSelect from "react-flags-select";

const Header = ({ setIsShowAuth }) => {
  const dispatch = useDispatch();
  const profile = useSelector((store) => store.auth.profile);
  const router = useRouter();
  const token = localStorage.getItem('token');

  React.useEffect(() => {
    if (token && token !== 'null') {
      dispatch(setGetAuthorization(1))
    }
  }, [token]);

  const onSelect = (language) => {
    const select_language = language?.toLowerCase() === 'us' ? 'en' : language?.toLowerCase() === 'ru' ? 'ru' : 'kz';
    localStorage.setItem('language', select_language);
    window.location.replace(window.location.origin);
  }

  return (
    <div style={{ padding: '10px 20px', display: 'flex', justifyContent: 'space-between' }}>
      <Typography style={{ fontFamily: "Nosifer, sans-serif", fontSize: 24, color: '#fff' }}>Lakas</Typography>
      {
        !Boolean(token) && (
          <ReactFlagsSelect
            selected={localStorage.getItem('language')?.toLowerCase() === 'en' ? 'US' : localStorage.getItem('language')?.toLowerCase() === 'ru' ? "RU" : "KZ"}
            onSelect={onSelect}
            countries={["RU", "KZ", "US"]}
            placeholder="Select language"
            showSelectedLabel={false}
            showSecondarySelectedLabel={false}
            showSecondaryOptionLabel={false}
            selectedSize={40}
            fullWidth={true}
            style={{
              borderColor: '#fff'
            }}
          />
        )
      }
      {
        Boolean(token) && (
          <div style={{ display: 'flex' }}>
            <div style={{
              borderTopLeftRadius: 6,
              borderBottomLeftRadius: 6,
              background: '#A60C65',
              padding: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Balance />
            </div>
            <div style={{
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6,
              background: '#CF0E7E',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 5,
              fontFamily: 'Montserrat, sans-serif',
              color: '#ffffff',
              fontWeight: 600
            }}>
              {new Intl.NumberFormat('ru-RU').format(profile?.balance?.toFixed(2) || 0) + profile?.currency}
            </div>
          </div>
        )
      }
      {
        router.pathname.indexOf('/user') === -1 && (
          <MenuIcon style={{ color: '#fff', width: 40, height: 40 }}
            onClick={() => {
              if (localStorage.getItem('token')) {
                router.history.push('/user')
              } else {
                setIsShowAuth(true)
              }
            }}
          />
        )
      }
    </div>
  )
}

export default Header;