/* eslint-disable */
import {types} from './types';

export const setGetCheckReferralAction = (payload) => {
    return {
        type: types.GET_CHECK_REFERRAL,
        payload,
      }
}

export const setGetListReferralAction = (payload) => {
  return {
    type: types.GET_LIST_REFERRAL,
    payload
  }
}