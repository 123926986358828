/* eslint-disable */
import axios from 'axios';
import {backendHost} from '../../config';

export const postCreateReferral = (params) => {
    return axios.post(`${backendHost}/api/client/referrer/create`, params, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    });
};