/* eslint-disable */
import { postChangePassword } from '../api/postChangePassword';

export const setPostChangePassword = (params, setError, setSuccess) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postChangePassword(params);
            setSuccess(true);
            setError(false);
        } catch(error) {
            setSuccess(false);
            setError(true);
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}