/* eslint-disable */
import {types} from './types';

const initialState = {
    list: {
        transaction: [],
        count: 0
    },
    card: "",
    transaction: {}
}

export function transactionReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_TRANSACTION:
            return { ...state, list: action.payload};
        case types.SET_GET_CARD: 
            return { ...state, card: action.payload};
        case types.SET_GET_REPLENISHMENT_TRANSACTION:
            return { ...state, transaction: action.payload };
        default:
            return state
    }
}