import React from 'react';
import { ReactComponent as Cross } from './cross.svg';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import { Dialog, Slide } from '@mui/material';
import Header from './components/Header';
import GroupButton from './components/GroupButton';

const WelcomeBonus = ({
    setIsShowModalWelcomeBonus,
    isShowModalWelcomeBonus,
    setIsOpenReplenishment,
    setIsShowAuth
}) => {
    const containerRef = React.useRef(null);
    const [step, setStep] = React.useState(1);


    return (
        <Dialog open={isShowModalWelcomeBonus} onClose={() => console.log(1)}>
            <Slide in={isShowModalWelcomeBonus} container={containerRef.current} direction="up">
                <div style={{
                    // borderRadius: 24,
                    background: '#FFFFFF',
                    position: 'fixed',
                    paddingTop: 60,
                    paddingLeft: 10,
                    paddingRight: 10,
                    // padding: 60,
                    zIndex: 5000,
                    overflow: 'scroll',
                    height: '100%',
                    top: 0,
                    left: 0,
                }}>
                  
                        <div onClick={() => {
                            setIsShowModalWelcomeBonus(false);
                            setStep(1)
                        }} style={{
                            cursor: 'pointer',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            minWidth: 24,
                            width: 24,
                            height: 24,
                            background: '#edf0f7',
                            borderRadius: 6,
                            position: 'absolute',
                            right: 20,
                            top: 20
                        }}>
                            <Cross style={{ width: 9, height: 9 }} />
                        </div>
                    <Header setIsOpenReplenishment={setIsOpenReplenishment} setIsShowAuth={setIsShowAuth} setIsShowModalWelcomeBonus={setIsShowModalWelcomeBonus} />
                    <div style={{ marginTop: 50, paddingBottom: 100, }}>
                        <div style={{ textAlign: 'center', marginBottom: 50 }}>
                            <h1 style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 36,
                                fontWeight: 900,
                                color: '#090f1e',
                                lineHeight: 1.2,
                                padding: 0,
                                margin: 0,
                            }}>Как забрать бонус?</h1>
                        </div>
                        <div style={{ position: 'relative' }}>
                            {
                                step === 1 && (
                                    <StepOne />
                                )
                            }
                            {
                                step === 2 && (
                                    <StepTwo />
                                )
                            }
                            {
                                step === 3 && (
                                    <StepThree />
                                )
                            }

                            <GroupButton step={step} setStep={setStep} />
                        </div>
                    </div>
                </div>
            </Slide>
        </Dialog>
    )
}

export default WelcomeBonus;