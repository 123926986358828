/* eslint-disable */
import React from 'react';


const Banner = () => {
  const language = localStorage.getItem('language');

  return (
    <div style={{ maxWidth: 1140, width: '100%', display: 'flex', justifyContent: 'space-between', margin: '0 auto', marginTop: 20 }}>
      <div style={{background: '#757B96', backgroundImage: 'url(assets/banner/leto.png)',  backgroundSize: 'cover', width: 370, height: 200, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5, backgroundSize: 'cover'}}>

      </div>
      <div style={{background: '#757B96', backgroundImage: 'url(assets/banner/two1.jpg)',  backgroundSize: 'cover', width: 370, height: 200, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5}}>

      </div>
      <div style={{background: '#757B96', backgroundImage: 'url(assets/banner/four1.jpg)', backgroundSize: 'cover', width: 370, height: 200, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5}}>

      </div>
    </div>
  )
}

export default Banner;