import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const CarouselList = ({list}) => {
    const [indexActive, setIndexActive] = React.useState(0);
    const profile = useSelector(state => state.auth.profile);
    if (list.length === 1) {
        return (
            <Carousel
                autoPlay={true}
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                showArrows={false}
                selectedItem={indexActive}
                emulateTouch
                interval={1500}
                onChange={(v) => setIndexActive(v)}
            >
                {
                    list?.[0] && (
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <Typography style={{ color: 'gold', fontWeight: 700, fontSize: 24, textShadow: '2px 2px 2px #000000' }}>JACKPOT {list?.[0].jackpot.toString().toUpperCase()}</Typography>
                            <Typography style={{ color: 'gold', fontWeight: 700, fontSize: 24, textShadow: '2px 2px 2px #000000' }}>{new Intl.NumberFormat('ru-RU').format(list?.[0].primary_amount?.toFixed(2))} {profile?.currency}</Typography>
                        </div>
                    )
                }
            </Carousel>
        )
    }

    const getColor = (value) => {
        switch(value) {
            case "gold": return "gold";
            case "silver": return "silver";
            case "bronze": return "#CD7F32";
        }
    }

    return (
        <Carousel
            autoPlay={true}
            infiniteLoop
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            showArrows={false}
            selectedItem={indexActive}
            emulateTouch
            interval={1500}
            onChange={(v) => setIndexActive(v)}
        >
            {
                list?.[0] && (
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Typography style={{ color: getColor(list?.[0].jackpot.toString().toLowerCase()), fontWeight: 700, fontSize: 24, textShadow: '1px 1px 1px #000000' }}>JACKPOT {list?.[0].jackpot.toString().toUpperCase()}</Typography>
                        <Typography style={{ color: getColor(list?.[0].jackpot.toString().toLowerCase()), fontWeight: 700, fontSize: 24, textShadow: '1px 1px 1px #000000' }}>{new Intl.NumberFormat('ru-RU').format(list?.[0].primary_amount?.toFixed(2))} {profile?.currency}</Typography>
                    </div>
                )
            }
            {
                list?.[1] && (
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Typography style={{ color: getColor(list?.[1].jackpot.toString().toLowerCase()), fontWeight: 700, fontSize: 24, textShadow: '2px 2px 2px #000000' }}>JACKPOT {list?.[1].jackpot.toString().toUpperCase()}</Typography>
                        <Typography style={{ color: getColor(list?.[1].jackpot.toString().toLowerCase()), fontWeight: 700, fontSize: 24, textShadow: '2px 2px 2px #000000' }}>{new Intl.NumberFormat('ru-RU').format(list?.[1].primary_amount?.toFixed(2))} {profile?.currency}</Typography>
                    </div>
                )
            }
            {
                list?.[2] && (
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Typography style={{ color: getColor(list?.[2].jackpot.toString().toLowerCase()), fontWeight: 700, fontSize: 24, textShadow: '1px 1px 1px #000000' }}>JACKPOT {list?.[2].jackpot.toString().toUpperCase()}</Typography>
                        <Typography style={{ color: getColor(list?.[2].jackpot.toString().toLowerCase()), fontWeight: 700, fontSize: 24, textShadow: '1px 1px 1px #000000' }}>{new Intl.NumberFormat('ru-RU').format(list?.[2].primary_amount?.toFixed(2))} {profile?.currency}</Typography>
                    </div>
                )
            }
        </Carousel>
    )
}

export default CarouselList;