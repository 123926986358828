/* eslint-disable */
import React from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setGetGamesList } from '../../store/games/thunk/setGetGamesList';
import { setGetGamesListAction } from '../../store/games/action';
import { useRouter } from '../../components/hook/useRouter';
import {translate} from '../../dictionary';

const Slot = ({game, setIsOpen}) => {
  const [isActive, setIsActive] = React.useState('all');
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    dispatch(setGetGamesList({
      page: 1,
      type: 'slots',
      provider: '',
      search: '',
      is_desktop: 1,
      is_mobile: 0
    }))
  }, []);

  const games = useSelector((state) => state.games.list);
  const router = useRouter();

  return (
    <div style={{ background: '#1B2036' }} id="slot">

      <div style={{ maxWidth: 1140, width: '100%', margin: '0 auto', paddingTop: !game ? 50 : 0}}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', rowGap: 12 }}>
          <div style={{ marginTop: 12, display: 'flex', justifyContent: 'center' }}>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, padding: '0 10px 10px', cursor: 'pointer', color: isActive === 'all' ? '#FFFFFF' : '#757B96', borderBottom: isActive === 'all' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('all');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: '',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              {translate('game.all')}
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, padding: '0 10px 10px', cursor: 'pointer', color: isActive === 'Greentube' ? '#FFFFFF' : '#757B96', borderBottom: isActive === 'Greentube' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('Greentube');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'Greentube',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              Greentube
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'EGT' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'EGT' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('EGT');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'EGT',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              EGT
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'Igrosoft' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'Igrosoft' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('Igrosoft');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'Igrosoft',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('');
                } else {
                  setIsOpen(true)
                }
              }}
            >
              Igrosoft
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'Belatra' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'Belatra' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('Belatra');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'Belatra',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              Belatra
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'NYX' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'NYX' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('NYX');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'NYX',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true);
                }
              }}
            >
              NYX
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'NetEnt' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'NetEnt' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('NetEnt');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'NetEnt',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              NetEnt
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'PlayNGo' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'PlayNGo' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('PlayNGo');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'PlayNGo',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('');
                } else {
                  setIsOpen(true);
                }
              }}
            >
              PlayNGo
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'RedTiger' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'RedTiger' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('RedTiger');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'RedTiger',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              RedTiger
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'Qilin' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'Qilin' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('Qilin');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'Qilin',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              Qilin
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'Amatic' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'Amatic' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('Amatic');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'Amatic',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              Amatic
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'Pragmatic' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'Pragmatic' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('Pragmatic');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'Pragmatic',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              Pragmatic
            </Typography>
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, color: isActive === 'BrandGames' ? '#FFFFFF' : '#757B96', padding: '0 10px 10px', cursor: 'pointer', borderBottom: isActive === 'BrandGames' ? '1px solid #521FFF' : '1px solid rgba(0,0,0,.3)' }}
              onClick={() => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('BrandGames');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: 'BrandGames',
                    search: '',
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch('')
                } else {
                  setIsOpen(true)
                }
              }}
            >
              BrandGames
            </Typography>
          </div>
          <div>
            <input 
              type="text" 
              style={{width: 350, marginLeft: 20, padding: 10, background: 'none', border: '1px solid rgba(255,255,255,.2)', color: '#757B96', borderRadius: 12, outline: 'none', fontSize: 16 }} 
              placeholder={translate('game.search2')}
              value={search}
              onChange={(v) => {
                if (localStorage.getItem('token')) {
                  dispatch(setGetGamesListAction({list: [], page: 1}))
                  setIsActive('all');
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: '',
                    search: v.target.value.toString().toLowerCase(),
                    is_desktop: 1,
                    is_mobile: 0
                  }));
                  setPage(1);
                  setSearch(v.target.value)
                } else {
                  setIsOpen(true)
                }
              }}
            />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 20, flexWrap: 'wrap', gap: 22, minHeight: 500 }}>
          {
            games?.list.map((item) => {
              return (
                <img key={item.uuid} src={item.image} alt={item.name} onClick={() => {
                  if (localStorage.getItem('token')) {
                    // navigate("/"); 
                    setTimeout(() => {
                      window.location.replace('/game/' + item.uuid);
                    }, 100)
                  } else {
                    setIsOpen(true);
                  }
                }} style={{ cursor: 'pointer', minWidth: 210, width: 210, minHeight: 141.22,  height: 141.22, objectFit: 'cover', boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5 }} />
              )
            })
          }

        </div>
        {
          !!games.list.length && games.list.length >= 25 && (
            <Button
              variant="contained"
              style={{ width: '100%', marginTop: 20 }}
              onClick={() => {
                dispatch(setGetGamesList({
                  page: page + 1,
                  type: 'slots',
                  provider: isActive === 'all' ? '' : isActive,
                  search: '',
                  is_desktop: 1,
                  is_mobile: 0
                }));
                setPage(page + 1)
              }}
            >{translate('common.more_load')}</Button>
          )
        }

      </div>

    </div>
  )
}

export default Slot