import {postConfirmationStepRegistration} from '../api/postConfirmationStepRegistration';

export const setPostConfirmationStepRegistration = (params, setShowModalConfirm, setError) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postConfirmationStepRegistration(params);
            setShowModalConfirm(params.code + String(params.login));
        } catch(error) {
            if (setError) {
                setError(true)
            }
        }
    }
}