import React from 'react';
import Banner from './banner3.jpg';
import { useSelector, useDispatch } from 'react-redux';
import CarouselList from './carousel';
import { setGetJackpotList } from '../../../store/jackpot/thunk/setGetJackpotList';

const Jackpot = () => {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.auth.profile);

    React.useEffect(() => {        
        dispatch(setGetJackpotList({
            group: profile.groups_cashiers || localStorage.getItem('token') ? profile?.groups_cashiers : 'all'
        }));
    }, [profile?.groups_cashiers])

    const jackpot = useSelector(state => state.jackpot.list);
    return (
        <div style={{margin: '10px 20px 0', backgroundImage: `url(${Banner})`, height: 80, borderRadius: 8, boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, .5)'}}>
            <CarouselList list={jackpot}/>
        </div>
    )
}

export default Jackpot