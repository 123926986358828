import React from 'react';
import { Dialog, Typography, TextField, Button } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setPostWithdrawalCreate } from '../../store/transaction/thunk/setPostWithdrawalCreate';
import { translate } from '../../dictionary';

const Withdrawal = ({ setIsOpenWithdrawal, isOpenWithdrawal, profile, mobile }) => {
    const dispatch = useDispatch();
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState(false);

    const { register, handleSubmit, control, setValue, reset } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            amount: '',
            card: ''
        }
    });

    const amount = useWatch({
        control,
        name: 'amount'
    })

    const onSubmit = (store) => {
        if (profile.balance < store.amount) {
            setError(translate('withdrawals.amount_more_balance'))
        } else if (profile.wager > 0) {
            setError(translate('withdrawals.wager'))
        } else if (!isNaN(Number(store.amount)) && store.card) {
            dispatch(setPostWithdrawalCreate(store, setSuccess))
        }
    }

    React.useEffect(() => {
        setError('')
    }, [amount])

    if (mobile) {
        return (
            <Dialog open={isOpenWithdrawal} onClose={() => {
                reset();
                setIsOpenWithdrawal(false);
                setSuccess(false);
            }}>
                <div style={{ background: '#1b2036', width: 300, height: 270, padding: 10 }}>
                    <div style={{ textAlign: 'center' }}>
                        <Typography color="white" style={{ fontSize: 22 }}>{translate('withdrawals.title')}</Typography>
                    </div>
                    {
                        profile?.currency !== 'RUB' && (
                            <div style={{ padding: '0 10px', textAlign: 'center', marginTop: 20 }}>
                                <Typography color="white" style={{ fontSize: 18 }}>{translate('withdrawal')}</Typography>
                            </div>
                        )
                    }
                    {
                        success && profile?.currency === 'RUB' && (
                            <div style={{ marginTop: 80 }}>
                                <Typography color='#59d428' style={{ textAlign: 'center' }}>{translate('withdrawals.request_success')}</Typography>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                    <Button variant="contained" onClick={() => {
                                        setIsOpenWithdrawal(false)
                                        setSuccess(false);
                                        reset()
                                    }}>{translate('withdrawals.btn_to_home')}</Button>
                                </div>
                            </div>
                        )
                    }
                    {
                        !success && profile?.currency === 'RUB' && (
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" style={{ width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <TextField
                                    {...register('card')}
                                    id="amount"
                                    label={translate('withdrawals.card_number')}
                                    type="text"
                                    focused={true}
                                    autoComplete="current-amount"
                                    variant="outlined"
                                    style={{ width: '300px' }}
                                    InputLabelProps={{
                                        style: { color: '#fff', borderColor: '#fff' },
                                    }}
                                    sx={{
                                        input: {
                                            color: '#fff', borderColor: '#fff'
                                        }
                                    }}
                                />
                                <div style={{ height: 12 }} />
                                <TextField
                                    {...register('amount')}
                                    id="amount"
                                    label={translate('withdrawals.amount')}
                                    type="text"
                                    focused={true}
                                    autoComplete="current-amount"
                                    variant="outlined"
                                    style={{ width: '300px' }}
                                    InputLabelProps={{
                                        style: { color: '#fff', borderColor: '#fff' },
                                    }}
                                    sx={{
                                        input: {
                                            color: '#fff', borderColor: '#fff'
                                        }
                                    }}
                                    error={isNaN(Number(amount)) || profile.wager > 0 || error}
                                    helperText={isNaN(Number(amount)) ? translate('withdrawals.correct_amount') : profile.wager > 0 ? translate('withdrawals.wager') : error}
                                />

                                <div style={{ width: '80%', marginTop: 40 }}>
                                    <Button variant="contained" type='onsubmit' style={{ width: '100%' }}>{translate('withdrawals.btn')}</Button>
                                </div>
                            </form>
                        )
                    }
                </div>
            </Dialog>
        )
    }

    return (
        <Dialog open={isOpenWithdrawal} onClose={() => {
            setIsOpenWithdrawal(false);
            setSuccess(false);
            reset()
        }}>
            <div style={{ background: '#1b2036', width: 500, height: 300, padding: 20 }}>
                <div style={{ textAlign: 'center' }}>
                    <Typography color="white" style={{ fontSize: 22 }}>{translate('withdrawals.title')}</Typography>
                </div>
                {
                    profile?.currency !== 'RUB' && (
                        <div style={{ padding: '0 10px', textAlign: 'center', marginTop: 20 }}>
                            <Typography color="white" style={{ fontSize: 18 }}>{translate('withdrawal')}</Typography>
                        </div>
                    )
                }
                {
                    profile?.currency === 'RUB' && (
                        <>
                            {
                                success && (
                                    <div style={{ marginTop: 80 }}>
                                        <Typography color='#59d428' style={{ textAlign: 'center' }}>{translate('withdrawals.request_success')}</Typography>
                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                            <Button variant="contained" onClick={() => {
                                                setIsOpenWithdrawal(false);
                                                setSuccess(false);
                                                reset()
                                            }}>{translate('withdrawals.btn_to_home')}</Button>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                !success && (
                                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" style={{ width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <TextField
                                            {...register('card')}
                                            id="amount"
                                            label={translate('withdrawals.card_number')}
                                            type="text"
                                            focused={true}
                                            autoComplete="current-amount"
                                            variant="outlined"
                                            style={{ width: '300px' }}
                                            InputLabelProps={{
                                                style: { color: '#fff', borderColor: '#fff' },
                                            }}
                                            sx={{
                                                input: {
                                                    color: '#fff', borderColor: '#fff'
                                                }
                                            }}
                                        />
                                        <div style={{ height: 12 }} />
                                        <TextField
                                            {...register('amount')}
                                            id="amount"
                                            label={translate('withdrawals.amount')}
                                            type="text"
                                            focused={true}
                                            autoComplete="current-amount"
                                            variant="outlined"
                                            style={{ width: '300px' }}
                                            InputLabelProps={{
                                                style: { color: '#fff', borderColor: '#fff' },
                                            }}
                                            sx={{
                                                input: {
                                                    color: '#fff', borderColor: '#fff'
                                                }
                                            }}
                                            error={isNaN(Number(amount)) || profile.wager > 0 || error}
                                            helperText={isNaN(Number(amount)) ? translate('withdrawals.correct_amount') : profile.wager > 0 ? translate('withdrawals.wager') : error}
                                        />

                                        <div style={{ width: '80%', marginTop: 40 }}>
                                            <Button variant="contained" type='onsubmit' style={{ width: '100%' }}>{translate('withdrawals.btn')}</Button>
                                        </div>
                                    </form>
                                )
                            }
                        </>
                    )
                }

            </div>
        </Dialog>
    )
}

export default Withdrawal;