import React from 'react';
import { ReactComponent as Cross } from './cross.svg';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import StepThree from './components/StepThree';
import { Slide } from '@mui/material';
import Header from './components/Header';
import GroupButton from './components/GroupButton';

const WelcomeBonus = ({
    setIsShowModalWelcomeBonus,
    isShowModalWelcomeBonus,
    setReplenishment,
    setIsOpen
}) => {
    const containerRef = React.useRef(null);
    const [step, setStep] = React.useState(1);
    return (

        <div style={{
            position: 'fixed',
            background: 'rgba(0,0,0,.5)',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            zIndex: isShowModalWelcomeBonus ? 1000 : -50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Slide in={isShowModalWelcomeBonus} container={containerRef.current} direction="up">
                <div style={{
                    borderRadius: 24,
                    background: '#FFFFFF',
                    position: 'relative',
                    padding: 60,
                    overflow: 'hidden'
                }}>
                    <div onClick={() => {
                        setIsShowModalWelcomeBonus(false);
                        setStep(1)
                    }} style={{
                        cursor: 'pointer',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        minWidth: 24,
                        height: 24,
                        background: '#edf0f7',
                        borderRadius: 6,
                        position: 'absolute',
                        right: 20,
                        top: 20
                    }}>
                        <Cross style={{ width: 9, height: 9 }} />
                    </div>
                    <Header setReplenishment={setReplenishment} setIsOpen={setIsOpen} />
                    <div style={{ marginTop: 50 }}>
                        <div style={{ textAlign: 'center', marginBottom: 50 }}>
                            <h1 style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 36,
                                fontWeight: 900,
                                color: '#090f1e',
                                lineHeight: 1.2,
                                padding: 0,
                                margin: 0,
                            }}>Как забрать бонус?</h1>
                        </div>
                        <div style={{ position: 'relative' }}>
                            {
                                step === 1 && (
                                    <StepOne />
                                )
                            }
                            {
                                step === 2 && (
                                    <StepTwo />
                                )
                            }
                            {
                                step === 3 && (
                                    <StepThree />
                                )
                            }
                            <GroupButton step={step} setStep={setStep} />
                        </div>
                    </div>
                </div>
            </Slide>
        </div>

    )
}

export default WelcomeBonus;