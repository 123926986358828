/* eslint-disable */
import axios from 'axios';
import {backendHost} from '../../config';

export const getAuthorization = () => {
    return axios.get(`${backendHost}/api/client/v2/profile`, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    });
};