import React from 'react';

const Header = ({
    setIsOpenReplenishment,
    setIsShowAuth,
    setIsShowModalWelcomeBonus
}) => {
    return (
        <div style={{ borderBottom: '1px solid #090f1e0d', paddingBottom: 50 }}>
            <div style={{ textAlign: 'center' }}>
                <h3 style={{
                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                    fontSize: 52,
                    fontWeight: 900,
                    color: '#090f1e',
                    lineHeight: 1.1,
                    padding: 0,
                    margin: 0,
                }}>Бонус <span style={{
                    color: '#ff9815',
                    backgroundImage: 'linear-gradient(99.83deg, #fdc63d -48.63%, #ff9815 97.54%)',
                    backgroundClip: 'text'
                }}>+500%</span><br /> на депозит</h3>
            </div>
            <div style={{ marginTop: 20, maxWidth: 552, width: '100%', textAlign: 'center', wordBreak: 'break-word' }}>
                <span style={{
                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                    fontSize: 16,
                    color: '#090f1e',
                    lineHeight: 1.56
                }}>Первые 4 пополнения основного счета дают вам дополнительный <br /> бонус к депозиту на бонусный счет</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }} onClick={() => {
                if (localStorage.getItem('token')) {
                    setIsOpenReplenishment(true)
                } else {
                    setIsShowModalWelcomeBonus(false)
                    setIsShowAuth(true)
                }
            }}>
                <div style={{
                    backgroundImage: 'linear-gradient(99.83deg,#fdc63d -48.63%,#ff9815 97.54%)',
                    boxShadow: '0 12px 24px #ffa62159',
                    borderRadius: 10,
                    padding: '0 35px',
                    height: 50,
                    fontWeight: 700,
                    fontSize: 16,
                    lineHeight: 1.25,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                    fontSize: 16,
                    color: '#FFFFFF',
                    cursor: 'pointer'
                }}>
                    <span>Получить бонус</span>
                </div>
            </div>
        </div>
    )
}

export default Header;