import React from 'react';
import StepImage from './step3.png';
import { Slide } from '@mui/material';

const StepThree = () => {
    const containerRef = React.useRef(null);
    return (
        <div style={{ minHeight: 395, maxHeight: 395, height: 395 }}>
            <div style={{ display: 'flex', marginBottom: 25 }}>
                <div style={{
                    backgroundImage: 'linear-gradient(99.83deg,#fdc63d -48.63%,#ff9815 97.54%)',
                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                    color: '#FFF',
                    padding: '5px 11px',
                    borderRadius: 6,
                    display: 'inline-block',
                    fontSize: 11,
                    lineHeight: '13px',
                    letterSpacing: '.275px',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                }}>
                    <span>ШАГ 3</span>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Slide in={true} container={containerRef.current} direction="right">
                    <img src={StepImage} alt="" style={{ height: 140 }} />
                </Slide>
                <Slide in={true} container={containerRef.current} direction="left">
                    <div style={{ maxWidth: 280, width: '100%' }}>
                        <h3 style={{
                            fontFamily: '-apple-system,SF Pro Text,sans-serif',
                            color: '#090f1e',
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            fontSize: 24,
                            lineHeight: '28px',
                            marginBottom: 15
                        }}>Играйте</h3>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: 20 }}>
                                <span style={{
                                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                    fontSize: 14,
                                    color: '#090f1e',
                                    lineHeight: 1.56
                                }}>Играйте в казино, и бонус станет доступным и перейдет на основной счет.</span>
                            </div>

                            <span style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 16,
                                color: '#090f1e',
                                lineHeight: 1.56
                            }}>Wager</span>
                            <span style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 16,
                                color: '#090f1e',
                                lineHeight: 1.56
                            }}>&mdash; на бонус 1 депозита х30.</span>
                            <span style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 16,
                                color: '#090f1e',
                                lineHeight: 1.56
                            }}>&mdash; на бонус 2 депозита х25.</span>
                            <span style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 16,
                                color: '#090f1e',
                                lineHeight: 1.56
                            }}>&mdash; на бонус 3 депозита х20.</span>
                            <span style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 16,
                                color: '#090f1e',
                                lineHeight: 1.56
                            }}>&mdash; на бонус 4 депозита х15.</span>
                        </div>
                    </div>
                </Slide>
            </div>
        </div>
    )
}

export default StepThree;