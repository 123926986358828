/* eslint-disable */
import { Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as Present } from '../assets/present.svg';
import FreespinStepOne from './fs/step1';
import FreespinStepTwo from './fs/step2';
import FreespinStepThree from './fs/step3';
import FreespinStepFour from './fs/step4';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../dictionary';
import WelcomeBonus from './components/WelcomeBonus';
import { setPostPromocode } from '../../store/bonuses/thunk/setPostPromocode';
import Modals from './fs/modals';

const Bonuses = ({
  setReplenishment,
  setIsOpen
}) => {
  const [isShowModalWelcomeBonus, setIsShowModalWelcomeBonus] = React.useState(false);
  const dispatch = useDispatch();
  const [promocode, setPromocode] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [messageModal, setMessageModal] = React.useState('');

  const profile = useSelector(state => state.auth.profile);
  const banner = (Number(profile?.fs?.quantity) > 0 && profile?.fs?.status) || (Number(profile?.fs?.quantity) === 0 && profile?.fs?.status && profile?.fs?.bonus_balance && profile?.fs?.win_amount > 0) || (profile?.fs_played?.status && profile?.fs_played?.wager_played < profile?.fs_played?.wager && Number(profile?.fs_played?.date_from) + 259200000 >= new Date().getTime()) || (profile?.fs_played?.status && profile?.fs_played?.wager_played >= profile?.fs_played?.wager && Number(profile?.fs_played?.date_from) + 259200000 >= new Date().getTime());
  const language = localStorage.getItem('language');

  return (
    <div style={{ background: '#1B2036' }} id="bonus">
      <div style={{ maxWidth: 1140, width: '100%', margin: '0 auto', paddingTop: 50, paddingBottom: 50 }}>
        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 12, justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Present />
            <Typography
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 18, fontWeight: 400, padding: '0 10px 10px', cursor: 'pointer', paddingTop: 13, color: '#fff' }}
            >
              {translate('bonus.title')}
            </Typography>
          </div>
          {
            profile?.login && profile?.connection_groups !== 'true' && (
            <div style={{display: 'flex', alignItems: 'center',columnGap: 12, position: 'relative'}}>
              <input type="text" value={promocode} onChange={(e) => setPromocode(e.target.value)} style={{padding: '5px 10px', outline: 'none', width: 250, fontSize: 16, textTransform: 'uppercase', height: 30, fontFamily: "Montserrat, sans-serif",}} placeholder={translate('referral.placeholder.input_promocode')}/>
              <div 
                onClick={() => dispatch(setPostPromocode({promocode}, setShowModal, setMessageModal))}
                style={{display: 'flex', width: 35, height: 35, background: '#2F2FDD', borderRadius: 8, justifyContent: 'center', position: 'absolute', top: 5, right: 5, cursor: 'pointer'}}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="20" fill="#fff">
                    <path d="M19.414 27.414l10-10c0.781-0.781 0.781-2.047 0-2.828l-10-10c-0.781-0.781-2.047-0.781-2.828 0s-0.781 2.047 0 2.828l6.586 6.586h-19.172c-1.105 0-2 0.895-2 2s0.895 2 2 2h19.172l-6.586 6.586c-0.39 0.39-0.586 0.902-0.586 1.414s0.195 1.024 0.586 1.414c0.781 0.781 2.047 0.781 2.828 0z"></path>
                </svg>
              </div>
            </div>
            )
          }  
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          {
            Number(profile?.fs?.quantity) > 0 && profile?.fs?.status && (
              <FreespinStepOne fs={profile?.fs} />
            )
          }
          {
            Number(profile?.fs?.quantity) === 0 && profile?.fs?.status && profile?.fs?.bonus_balance && profile?.fs?.win_amount > 0 && (
              <FreespinStepTwo fs={profile?.fs} />
            )
          }
          {
            profile?.fs_played?.status && profile?.fs_played?.wager_played < profile?.fs_played?.wager && Number(profile?.fs_played?.date_from) + 259200000 >= new Date().getTime() && (
              <FreespinStepThree fs={profile?.fs_played} />
            )
          }
          {
            profile?.fs_played?.status && profile?.fs_played?.wager_played >= profile?.fs_played?.wager && Number(profile?.fs_played?.date_from) + 259200000 >= new Date().getTime() && (
              <FreespinStepFour fs={profile?.fs_played} />
            )
          }
          <div style={{ position: 'relative' }}>
            <img
              src="/assets/bonus/first_1.jpg"
              alt=""
              style={{ boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5, width: 350, height: 400 }}
            />
            <div style={{ position: 'absolute', bottom: 65, left: 20 }}>
              <span style={{
                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                fontSize: 36,
                fontWeight: 900,
                color: '#FFF',
                lineHeight: 1.1,
                padding: 0,
                margin: 0,
              }}>Бонус <span style={{
                color: '#FEF723',
                backgroundImage: 'linear-gradient(99.83deg, #FEF723 -48.63%, #ff9815 97.54%)',
                backgroundClip: 'text'
              }}>+500%</span><br /> на депозит</span>
            </div>
            <div style={{ position: 'absolute', bottom: 15, left: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }} onClick={() => setIsShowModalWelcomeBonus(true)}>
                <div style={{
                  backgroundImage: 'linear-gradient(99.83deg,#fdc63d -48.63%,#ff9815 97.54%)',
                  boxShadow: '0 12px 24px #ffa62159',
                  borderRadius: 10,
                  padding: '0 35px',
                  height: 40,
                  fontWeight: 700,
                  fontSize: 14,
                  lineHeight: 1.25,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontFamily: '-apple-system,SF Pro Text,sans-serif',
                  fontSize: 16,
                  color: '#FFFFFF',
                  cursor: 'pointer'
                }}>
                  <span>Получить бонус</span>
                </div>
              </div>
            </div>
          </div>
          <img src={language === 'kz' ? "/assets/bonus/cashback_kz.jpg" : language === 'en' ? "/assets/bonus/cashback_en.jpg" : "/assets/bonus/cashback.jpg"} alt="" style={{ boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5, width: 350, height: 400 }} />
          {
            !banner && (
              <img src={language === 'kz' ? "/assets/bonus/tournament_kz.jpg" : language === 'en' ? "/assets/bonus/tournament_en.jpg" : "/assets/bonus/tournament.jpg"} alt="" style={{ boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5, width: 350, height: 400 }} />
            )
          }
        </div>
      </div>
      {
        showModal && <Modals message={messageModal} setShowModal={setShowModal} />
      }
      <WelcomeBonus
        setIsShowModalWelcomeBonus={setIsShowModalWelcomeBonus}
        isShowModalWelcomeBonus={isShowModalWelcomeBonus}
        setReplenishment={setReplenishment}
        setIsOpen={setIsOpen}
      />
    </div>
  )
}

export default Bonuses;