
/* eslint-disable */
import { Typography } from '@mui/material';
import React from 'react';
import {ReactComponent as Fire} from '../assets/fire.svg';
import { useRouter } from '../../components/hook/useRouter';
import {translate} from '../../dictionary';

const Popular = ({setIsOpen}) => {
  const router = useRouter()
  const navigate = (value) => router.history.push(value)
  return (
    <div style={{ maxWidth: 1140, width: '100%', margin: '0 auto', marginTop: 30 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Fire />
        <Typography
          style={{ fontFamily: "Montserrat, sans-serif", fontSize: 18, fontWeight: 400, padding: '0 10px 10px', cursor: 'pointer', paddingTop: 13, color: '#fff' }}
        >
          {translate('common.popular')}
        </Typography>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <img src="/assets/games/lucky.jpg" alt="" onClick={() => {
          if (localStorage.getItem('token')) {
            navigate('/game/204cafd239d57808f86dc7d0f23454201ecb7fa0')
          } else {
            setIsOpen(true)
          }}} style={{ cursor: 'pointer', width: 459, height: 340, objectFit: 'cover', boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5 }} />
        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 5, marginBottom: 7 }}>
            <img src="/assets/games/book_of_ra.jpg" onClick={() => {
              if (localStorage.getItem('token')) {
                 navigate('/game/23ca989db9ca5ad94dbca17fa54a123f3f7efc9d')
              } else {
                setIsOpen(true)
              }}}  alt="" style={{  cursor: 'pointer', width: 222, height: 166.5, objectFit: 'cover', boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5 }} />
            <img src="/assets/games/keks.jpg" onClick={() => {
              if (localStorage.getItem('token')) {
                navigate('/game/602f21fa5d9f51beaccf663edfd1ded3afb1d6fd')
              } else {
                setIsOpen(true);
              }
            }}  alt="" style={{ cursor: 'pointer', width: 222, height: 166.5, objectFit: 'cover', boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5 }} />
            <img src="/assets/games/sharky.jpg" onClick={() => {
               if (localStorage.getItem('token')) {
                navigate('/game/4476405774a3c048d3cc4d3b83dc5774c5b3a6d1')
               } else {
                 setIsOpen(true)
               }
            }}  alt="" style={{cursor: 'pointer', width: 222, height: 166.5, objectFit: 'cover', boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5 }} />
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: 5 }}>
            <img src="/assets/games/always_hot.jpg" alt="" onClick={() => {
              if (localStorage.getItem('token')) {
                navigate('/game/dd416dd20f5ac16f554cda1b8c572bd171c86a7c')
              } else {
                setIsOpen(true)
              }
              }}  style={{cursor: 'pointer',  width: 222, height: 166.5, objectFit: 'cover', boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5 }} />
            <img src="/assets/games/dolphins.jpg" alt="" onClick={() => {
               if (localStorage.getItem('token')) {
                navigate('/game/a8e77ed3fa064bda3fd4974ecfc393e92d1b3574')
               } else {
                 setIsOpen(true)
               }
              }}  style={{cursor: 'pointer', width: 222, height: 166.5, objectFit: 'cover', boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5 }} />
            <img src="/assets/games/resident.jpg" alt="" onClick={() => {
               if (localStorage.getItem('token')) {
                 navigate('/game/d2332b8b4e9bbccaf91eff385adbcba319c2a7cd');
              } else {
                setIsOpen(true);
              }}}  style={{cursor: 'pointer', width: 222, height: 166.5, objectFit: 'cover', boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5 }} />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Popular