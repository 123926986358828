import React from 'react';
import StepImage from './step3.png';
import { Slide } from '@mui/material';
import { useSelector } from 'react-redux';

const Step = () => {
    const containerRef = React.useRef(null);
    const profile = useSelector((state)=> state.auth.profile);

    return (
        <div style={{  }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Slide in={true} container={containerRef.current} direction="right">
                    <img src={StepImage} alt="" style={{ height: 100 }} />
                </Slide>
                <Slide in={true} container={containerRef.current} direction="left">
                    <div style={{ maxWidth: 280, width: '100%' }}>
                        <h3 style={{
                            fontFamily: '-apple-system,SF Pro Text,sans-serif',
                            color: '#090f1e',
                            padding: 0,
                            margin: 0,
                            fontWeight: 700,
                            fontSize: 18,
                            lineHeight: '28px',
                            marginBottom: 15
                        }}>Играйте</h3>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: 20 }}>
                                <span style={{
                                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                    fontSize: 14,
                                    color: '#090f1e',
                                    lineHeight: 1.56
                                }}>Играйте в казино, и бонус станет доступным и перейдет на основной счет.</span>
                            </div>
                            <span style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 14,
                                color: '#090f1e',
                                lineHeight: 1.56
                            }}>Wager - <span style={{fontSize: 12, color: '#ACACAC'}}>x</span>{profile?.bonus?.wager}</span>
                            <span style={{
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                fontSize: 14,
                                color: '#090f1e',
                                lineHeight: 1.56
                            }}>Макс. сумма бонуса - <span style={{fontSize: 12, color: '#ACACAC'}}></span>{profile?.bonus?.bonus_max} {profile?.currency}</span>
                        </div>
                    </div>
                </Slide>
            </div>
        </div>
    )
}

export default Step;