/* eslint-disable */
import React from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useForm, useWatch } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setPostConfirmationStepRegistration } from '../../../store/authorization/thunk/setPostConfirmationStepRegistration';
import {translate} from '../../../dictionary';
import { setPostRegistration } from '../../../store/authorization/thunk/setPostRegistration';

const SignUp = ({ tab, setIsConfirm, control, handleSubmit, register, setValue, watch }) => {
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const currency = watch('currency');

  
  const onSubmitForm = (store) => {
    if (store.login?.length === 10 && store.password.length >= 6 && !isNaN(+store.login)) {
      const params = {
        login: store.login?.replace(/[^0-9]/g,""),
        password: store.password,
        type: 'phone',
        promocode: store.promocode.toString().toLowerCase(),
        currency,
        code: '7',
      }

      if (String(store?.login?.[0]) === '7') {
        const paramsRegistration = {
          login: String(params.code) + String(params.login),
          password: params.password,
          type: 'phone',
          promocode: params.promocode,
          currency,
          code: '7',
          activeBonus: ''
        }
        paramsRegistration.utm_source = localStorage.getItem('utm_source');
        paramsRegistration.utm_term = localStorage.getItem('utm_term');
        paramsRegistration.utm_medium = localStorage.getItem('utm_medium');
        paramsRegistration.utm_campaign = localStorage.getItem('utm_campaign');
        paramsRegistration.utm_content = localStorage.getItem('utm_content');
        
        dispatch(setPostRegistration(paramsRegistration));
      } else {
        dispatch(setPostConfirmationStepRegistration(params, setIsConfirm, setError))
      }
    }
  }

  const phone = useWatch({
    control,
    name: 'login'
  })

  const password = useWatch({
    control,
    name: 'password'
  })

  const [isShowCurrency, setIsShowCurrency] = React.useState(false);

  return (
    <div className='signup'>
      <div className='signup__tab'>
        <div className='signup__tab__title'>
          <span>{translate('signin.phone')}</span>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off">
        <div style={{ padding: '40px 15px', display: 'flex', flexDirection: 'column', rowGap: 25 }}>
          <label htmlFor="phones" style={{position:'relative'}}>
            <TextField
              {...register('login')}
              id="phone"
              label={translate('signin.phone_number')}
              type="text"
              autoComplete="new-phone"
              variant="outlined"
              style={{width: '100%'}}
              InputLabelProps={{
                style: { color: '#fff', width: '100%' },
              }}
              autoFocus={true}
              sx={{
                input: {
                  color: '#fff',
                  width: '100%', 
                  paddingLeft: 5
                }
              }}
              error={((phone.length < 10 || phone.length > 10) && phone.length > 3) || isNaN(+phone) || error}
              helperText={(phone.length > 3 && (phone.length < 10 || phone.length > 10)) || isNaN(+phone) ? translate('signin.enter_correct_phone') : error ? translate('signin.already_phone') : undefined}
            />
            <Typography color="white" style={{position: 'absolute', top: 16, left: 22, fontSize: 16}}>+7</Typography>
          </label>
         
          <TextField
            {...register('password')}
            id="filled-password-input"
            label={translate('password.label')}
            type="password"
            autoComplete="new-password"
            variant="outlined"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            sx={{
              input: {
                color: '#fff'
              }
            }}
            error={password.length < 6 && password.length > 1}
            helperText={password.length < 6 && password.length > 1 ? translate('signin.password.min_length') : undefined}
          />

          <TextField
            {...register('promocode')}
            id="filled-password-input"
            label={translate('signin.promocode')}
            type="text"
            required={false}
            autoComplete="current-password"
            variant="outlined"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            sx={{
              input: {
                color: '#fff'
              }
            }}
          />
          <span style={{
            color: '#fff',
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            lineHeight: 0
          }}>{translate('currency.label')}</span>
          <div style={{
            border: '1px solid rgba(0, 0, 0, 0.23)',
            height: 56,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 15,
            cursor: 'pointer',
            position: 'relative',
            borderRadius: 5
          }}
            onClick={() => {
              setIsShowCurrency(!isShowCurrency);
            }}
          >
            <div>
              <span style={{
                color: '#fff',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400
              }}>{currency ===  'RUB' ? translate('currency.ruble') : translate('currency.kzt')}</span>
            </div>
            {
              isShowCurrency && (
                <div style={{
                  position: 'absolute',
                  bottom: -56,
                  left: 0,
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                  width: '100%',
                  zIndex: 100,
                  background: "#FFF",
                  borderRadius: 5,
                }}>
                  <div style={{
                    height: 56,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 15
                  }}  onClick={() => setValue('currency', 'RUB')}>
                    <span style={{
                      color: '#333',
                      fontFamily: 'Roboto, sans-serif',
                      fontWeight: 400
                    }}>{translate('currency.ruble')}</span>
                  </div>
                  <div style={{
                    height: 56,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 15
                  }} onClick={() => setValue('currency', 'KZT')}>
                    <span style={{
                      color: '#333',
                      fontFamily: 'Roboto, sans-serif',
                      fontWeight: 400
                    }}>{translate('currency.kzt')}</span>
                  </div>
                </div>
              )
            }
          </div>
          <Button variant="contained" type='onsubmit'>{translate('common.register')}</Button>
        </div>
      </form>
    </div>
  )
}

export default SignUp;