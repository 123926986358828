/* eslint-disable */
import React from 'react';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setPostAuthorization } from '../../../../store/authorization/thunk/setPostAuthorization';
import { Typography } from '@mui/material';
import {translate} from '../../../../dictionary';

const SignIn = (props) => {
  const [tabs, setTabs] = React.useState('phone');
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);
  const { register, handleSubmit, control, reset, setValue  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      login: '',
      password: ''
    }
  });

  React.useEffect(() => {
    reset({
      login: '',
      password: '',
    })
  }, [tabs])

  const onSubmitForm = (store) => {
    if (tabs === 'phone') {
      const params = {
        login: '7' + String(store.login?.replace(/[^0-9]/g,"")),
        password: store.password
      }
      dispatch(setPostAuthorization(params, props.setIsShowAuth, null, setError));
    } else {
      const params = {
        login: store.login.toString().toLowerCase(),
        password: store.password
      }
      dispatch(setPostAuthorization(params, props.setIsShowAuth, null, setError));
    }
  }

  const handleChangeTab = (value) => {
    setTabs(value)
  }

  const phone = useWatch({
    control,
    name: 'login'
  })

  return (
    <div className='signin' >

      <div  className='signin__header'>
        <div 
          className={`signin__header__title ${tabs === 'phone' ? 'signin__header__title--active signin__header__title--active__left' : 'signin__header__title--disabled'}`} 
          onClick={() => handleChangeTab('phone')}
        >
          <span>
            {translate('signin.phone')}
          </span>
        </div>
        <div 
          className={`signin__header__title ${tabs === 'login' ? 'signin__header__title--active signin__header__title--active__right' : 'signin__header__title--disabled'}`} 
          onClick={() => handleChangeTab('login')}
        >
          <span>
            {translate('signin.login2')}
          </span>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmitForm)} autoComplete="off">
        <div className='signin__form' style={{position: 'relative'}}>
          {
            tabs === 'phone' && (
              <label htmlFor="phone" style={{ position: 'relative' }}>
                <TextField
                  {...register('login')}
                  id="phone"
                  label={translate('signin.phone_number')}
                  type="text"
                  autoComplete="new-phone"
                  variant="outlined"
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    style: { color: '#fff', width: '100%' },
                  }}
                  inputMode="decimal"
                  autoFocus={true}
                  sx={{
                    input: {
                      color: '#fff',
                      width: '100%',
                      paddingLeft: 5
                    }
                  }}
                  error={((phone.length < 10 || phone.length > 10) && phone.length > 3) || isNaN(+phone)}
                  helperText={(phone.length > 3 && (phone.length < 10 || phone.length > 10)) || isNaN(+phone) ? translate('signin.enter_correct_phone') : undefined}
                />
                <Typography color="white" style={{ position: 'absolute', top: 16, left: 22, fontSize: 16 }}>+7</Typography>
              </label>
            )
          }

          {
            tabs === 'login' && (
              <TextField
                {...register('login')}
                id="filled-password-input"
                label={translate('signin.login2')}
                type="text"
                autoComplete="new-login"
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{
                  input: {
                    color: '#fff'
                  }
                }}
              />
            )
          }
          
          <TextField
             {...register('password')}
            id="password"
            label={translate('password.label')}
            type="password"
            autoComplete="new-password"
            variant="outlined"
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            sx={{
              input: {
                color: '#fff'
              }
            }}
          />

          {
            error && (
              <Typography style={{padding: 0, margin: 0, position: 'absolute', bottom: 10, left: 20}} color="red">{translate('signin.loginOrPasswordIncorrect')}</Typography>
            )
          }

          <Button variant="contained" type='onsubmit'>{translate('common.signin')}</Button>
        </div>
      </form>
    </div>
  )
}

export default SignIn;