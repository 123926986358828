/* eslint-disable */
import { Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {ReactComponent as Back} from '../../../desktop/assets/back.svg';
import { setGetListReferralAction } from '../../../store/referral/action';
import { setGetCheckReferral } from '../../../store/referral/thunk/setGetCheckReferral';
import { setGetListReferral } from '../../../store/referral/thunk/setGetListReferral';
import { setPostCreateReferral } from '../../../store/referral/thunk/setPostCreateReferral';
import { useRouter } from '../../../components/hook/useRouter';
import {translate} from '../../../dictionary';

const Referral = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const { register, handleSubmit, control, reset, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      referral: ''
    }
  });

  const referralProfile = useSelector((state) => state.referral.profile);

  React.useEffect(() => {
    const newTemp = {
      list: [],
      count: 1,
      page: 1
  }

    dispatch(setGetListReferralAction(newTemp))
    dispatch(setGetCheckReferral());
    dispatch(setGetListReferral({
      page: 1,
      rowsPerPage: 50,
    }))
  }, []);

  React.useEffect(() => {
    reset({referral: referralProfile?.promocode?.toString().toLowerCase()})
  }, [referralProfile?.promocode])

  const onSubmitForm = (store) => {
    dispatch(setPostCreateReferral({
      label: store.referral
    }))
  }

  const referral = useWatch({
    control,
    name: 'referral'
  })
  const router = useRouter();
  let navigate = (value) => router.history.push(value)
  const list = useSelector((state) => state.referral.list);
  const profile = useSelector(state => state.auth.profile);

  return (
    <>
      <div style={{
        background: 'rgba(255, 255, 255, 0.12)',
        backdropFilter: 'blur(19.5px)',
        margin: '20px',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        rowGap: '10px',
        padding: 20
      }}>
        <div style={{display: 'flex', alignItems: 'center', position: 'absolute', top: -35, right: 10}} onClick={() => navigate('/user')}>
          <Back style={{width: 30, height: 30, fill: '#fff'}}/>
          <Typography style={{color: '#fff'}}>{translate('common.back')}</Typography>
        </div>
        <Typography style={{ color: '#fff' }}>
          {translate('referral.connect_friends')}
        </Typography>

        <form onSubmit={handleSubmit(onSubmitForm)} style={{width: '100%'}}>
          <label htmlFor="currentPassword" style={{width: '100%'}}>
            {
              !referralProfile?.promocode && <Typography color="#FFF">{translate('referral.need_create_promocode')}</Typography>
            }
            {
              referralProfile?.promocode && <Typography color="#FFF">{translate('referral.your_promocode')}</Typography>
            }
            
            <input
              {...register('referral')}
              id="referral"
              type="text"
              autoComplete="referral"
              style={{
                border: 'none', 
                background: 'rgba(14, 2, 48, 0.8)',
                outline: 'none',
                borderRadius: 4,
                padding: '5px 10px',
                width: 'calc(100% - 20px)',
                height: 30,
                color: '#fff',
                textTransform: 'uppercase'
              }}
              placeholder={translate('referral.placeholder.input_promocode')}
              disabled={referralProfile?.promocode}
            />
          </label>
          {
            referralProfile?.promocode && (
              <div style={{marginTop: 20}}>
                <Typography style={{color: 'white'}}>
                  {translate('referral.your_link')} <br />
                  <span style={{userSelect: 'all', color: '#0177FB'}}>
                    {window.location.origin}/?referral={referralProfile?.promocode}
                  </span>
                </Typography>
              </div> 
            )
          }
          {
            !referralProfile?.promocode && (
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <Button variant="contained" type='submit' style={{width: '100%', marginTop: 20}} disabled={!Boolean(referral)}>{translate('common.created')}</Button>
              </div>
            )
          }
        </form>
        {
          referralProfile?.promocode && (
            <>
              <Typography style={{ color: '#fff' }}>
                {translate('referral.your_balance')} {new Intl.NumberFormat('ru-RU').format(referralProfile?.balance?.toFixed(2) || 0) + profile?.currency}
              </Typography>
              <Typography style={{ color: '#fff' }}>
                {translate('referral.your_withdrawals')} {new Intl.NumberFormat('ru-RU').format(referralProfile?.output?.toFixed(2) || 0) + profile?.currency}
              </Typography>
            </>
          )
        }
        
      </div>
      <div style={{
        background: 'rgba(255, 255, 255, 0.12)',
        backdropFilter: 'blur(19.5px)',
        margin: '20px',
        borderRadius: '24px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        padding: 20,
      }}>
        <div>
          <Typography style={{ color: '#fff' }}>
            {translate('referral.statistics.title')}
          </Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', columnGap: 12, color: '#fff'}}>
          <Typography>ID</Typography>
          <Typography>{translate('referral.statistics.date_registration')}</Typography>
          <Typography>{translate('referral.statistics.profit')}</Typography>
        </div>
        <div>
          {
            list?.list?.map((item) => (
              <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff'}}>
                <Typography>{item.id}</Typography>
                <Typography>{format(new Date(+item.registration), 'dd.MM.yyyy')}</Typography>
                <Typography>{item?.sum_bet_promocode - item?.sum_win_promocode}</Typography>
              </div>
            ))
          }
        </div>
        {
          Number(list?.page?.currentPage) !== list?.page?.allPage && (
            <Button 
              variant="contained" 
              style={{width: '100%', marginTop: 20}}
              onClick={() => {
                dispatch(setGetListReferral({
                  page: page + 1,
                  rowsPerPage: 20
                }));
                setPage(page + 1)
              }}
            >{translate('common.more_load')}</Button>
          )
        }
      </div>
    </>
  )
}

export default Referral;