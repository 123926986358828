/* eslint-disable */
import { postPromocode } from '../api/postPromocode';
import { setGetAuthorization } from '../../authorization/thunk/setGetAuthorization';

export const setPostPromocode = (params, setShowModal, setMessageModal) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postPromocode(params);
            setShowModal(true);
            setMessageModal('fs issued');
            setTimeout(() => {
                dispatch(setGetAuthorization());
            }, 9000)

        } catch(error) {
            setShowModal(true);
            setMessageModal(error?.response?.data?.message)
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}