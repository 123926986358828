import { postReplenishemntCreate } from '../api/postReplenishemntCreate';

export const setReplenishmentCreate = (params, callback) => {
    return async (dispatch, getState) => {
        try {
            await postReplenishemntCreate(params);
            if (callback) {
                callback()
            }
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}