/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { setGameInit } from '../../../store/games/thunk/setGameInit';
import { setGameDemo } from '../../../store/games/thunk/setGetGameDemo';

const Game = () => {
    const dispatch = useDispatch()
    const params = useParams();
    const uuid = params.id;

    React.useEffect(() => {
        if (!localStorage.getItem('token')) {
            dispatch(setGameDemo({
                game_uuid: uuid,
            }))
        } else {
            dispatch(setGameInit({
                game_uuid: uuid,
            }))
        }
    }, [])
   
    const url = useSelector((state) => state.games.gameId);

    window.addEventListener("message", (e, key) => {
      if (e.data === 'GAME_MODE:CLOSED' || e.data === 'closeFrame') {
          window.location.replace(window.location.origin)
      }    
  }, false);
    
    return (
        <div style={{height: '100vh', background: '#1b1b1b'}}>            
            <iframe 
                id="frame"
                src={url} 
                width="100%" 
                height="100%" 
                frameborder="1"
                frameBorder="none"
                allowFullScreen="true"
                loading="eager"
            >
                    
            </iframe>
        </div>
    )
}

export default Game;