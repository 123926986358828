/* eslint-disable */
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGetTransaction } from '../../../store/transaction/thunk/setGetTransaction';
import { format } from 'date-fns';
import './transaction.css';
import {translate} from '../../../dictionary';

const Transaction = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    dispatch(setGetTransaction({
      page: 1,
      rowsPerPage: 20
    }))
  }, []);

  const list = useSelector((state) => state.transaction.list);

  return (
    <div style={{width: 'calc(100% - 40px)', minWidth: 960}}>
      <div style={{
        background: '#20253B',
        backdropFilter: 'blur(19.5px)',
        margin: '20px',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        rowGap: '10px',
        padding: '20px',
        marginTop: 0,
        boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)',
      }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Typography style={{ color: '#fff' }}>{translate('transaction.title')}</Typography>
        </div>

      </div>
      <div className='transaction'>
        {
          list.transaction.map((item, key) => (
            <div key={key} style={{ width: '100%', color: item.tag === "replenishment" ? '#34eb71' : '#ab3236', display: 'flex', columnGap: 12, justifyContent: 'space-between' }}>
              <Typography>{format(new Date(Number(item?.event_date)), 'dd.MM.yyyy hh:mm')}</Typography>
              <Typography>
                {item.tag.toString().toLowerCase() === 'replenishment' ? '+ ' : '- '}
                {new Intl.NumberFormat('ru-RU').format(Math.trunc(item.summ))} {item.currency?.toString().toUpperCase()}
              </Typography>
            </div>
          ))
        }
        {
          page !== list.count && !!list?.transaction?.length && (
            <Button 
              variant="contained" 
              style={{width: '100%', marginTop: 20}}
              onClick={() => {
                dispatch(setGetTransaction({
                  page: page + 1,
                  rowsPerPage: 20
                }));
                setPage(page + 1)
              }}
            >{translate('common.more_load')}</Button>
          )
        }
      </div>
    </div>

  )
}

export default Transaction;