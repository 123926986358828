/* eslint-disable */
import {getAuthorization} from '../api/getAuthorization';
import {setAuthorizeAction, setProfileAction} from '../action';

export const setGetAuthorization = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getAuthorization();

            if (data.status === 'success') {
                dispatch(setAuthorizeAction(true));
                dispatch(setProfileAction(data.profile));
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}