import { postWithdrawalCreate } from '../api/postWithdrawalCreate';
import { setGetAuthorization } from '../../authorization/thunk/setGetAuthorization';

export const setPostWithdrawalCreate = (params, setSuccess, setError) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postWithdrawalCreate(params);
            if (data.status === 'success') {
                setSuccess(true)
                dispatch(setGetAuthorization())
            }
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            } else {
                if (setError) {
                    setError(true)
                }
            }
        }
    }
}