/* eslint-disable */
import { Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setGetListReferralAction } from '../../../store/referral/action';
import { setGetCheckReferral } from '../../../store/referral/thunk/setGetCheckReferral';
import { setGetListReferral } from '../../../store/referral/thunk/setGetListReferral';
import { setPostCreateReferral } from '../../../store/referral/thunk/setPostCreateReferral';
import { translate } from '../../../dictionary';

const ReferralDesktop = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const { register, handleSubmit, control, reset, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      referral: ''
    }
  });

  const referralProfile = useSelector((state) => state.referral.profile);

  React.useEffect(() => {
    const newTemp = {
      list: [],
      count: 1,
      page: 1
  }

    dispatch(setGetListReferralAction(newTemp))
    dispatch(setGetCheckReferral());
    dispatch(setGetListReferral({
      page: 1,
      rowsPerPage: 50,
    }))
  }, []);

  React.useEffect(() => {
    reset({referral: referralProfile?.promocode?.toString().toLowerCase()})
  }, [referralProfile?.promocode])

  const onSubmitForm = (store) => {
    dispatch(setPostCreateReferral({
      label: store.referral
    }))
  }

  const referral = useWatch({
    control,
    name: 'referral'
  })

  const list = useSelector((state) => state.referral.list);
  const profile = useSelector(state => state.auth.profile);

  return (
    <>
      <div style={{
        background: '#20253B',
        backdropFilter: 'blur(19.5px)',
        margin: '20px',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        rowGap: '10px',
        padding: '20px',
        marginTop: 0,
        boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)',
      }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Typography style={{ color: '#fff' }}>{translate('referral.title')}</Typography>
        </div>

      </div>
      <div style={{
        background: '#20253B',
        backdropFilter: 'blur(19.5px)',
        margin: '20px',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        rowGap: '10px',
        padding: 20,
        boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)',
      }}>
        <Typography style={{ color: '#fff' }}>
          {translate('referral.connect_friends')}
        </Typography>

        <form onSubmit={handleSubmit(onSubmitForm)} style={{width: '100%'}}>
          <label htmlFor="currentPassword" style={{width: '100%'}}>
            {
              !referralProfile?.promocode && <Typography color="#FFF">{translate('referral.need_create_promocode')}</Typography>
            }
            {
              referralProfile?.promocode && <Typography color="#FFF">{translate('referral.your_promocode')}</Typography>
            }
            
            <input
              {...register('referral')}
              id="referral"
              type="text"
              autoComplete="referral"
              style={{
                border: 'none', 
                background: '#1B2036',
                outline: 'none',
                borderRadius: 4,
                padding: '5px 10px',
                width: 'calc(100% - 20px)',
                height: 30,
                color: '#fff',
                textTransform: 'uppercase'
              }}
              placeholder={translate('referral.placeholder.input_promocode')}
              disabled={referralProfile?.promocode}
            />
          </label>
          {
            referralProfile?.promocode && (
              <div style={{marginTop: 20}}>
                <Typography style={{color: 'white'}}>
                  {translate('referral.your_link')} <br />
                  <span style={{userSelect: 'all', color: '#0177FB'}}>
                    {window.location.origin}/?referral={referralProfile?.promocode}
                  </span>
                </Typography>
              </div> 
            )
          }
          {
            !referralProfile?.promocode && (
              <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <Button variant="contained" type='submit' style={{width: '100%', marginTop: 20}} disabled={!Boolean(referral)}>{translate('common.created')}</Button>
              </div>
            )
          }
        </form>
        {
          referralProfile?.promocode && (
            <>
              <Typography style={{ color: '#fff' }}>
                {translate('referral.your_balance')} {new Intl.NumberFormat('ru-RU').format(referralProfile?.balance?.toFixed(2) || 0) + profile?.currency}
              </Typography>
              <Typography style={{ color: '#fff' }}>
                {translate('referral.your_withdrawals')} {new Intl.NumberFormat('ru-RU').format(referralProfile?.output?.toFixed(2) || 0) + profile?.currency}
              </Typography>
            </>
          )
        }
        
      </div>
      
      <div style={{
        background: '#20253B',
        backdropFilter: 'blur(19.5px)',
        margin: '20px',
        borderRadius: '24px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
        padding: 20,
        boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)',
      }}>
        <div>
          <Typography style={{ color: '#fff' }}>
            {translate('referral.statistics.title')}
          </Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', columnGap: 12, color: '#fff'}}>
          <Typography style={{width: '20%', textAlign: 'center'}}>{translate('referral.statistics.login')}</Typography>
          <Typography style={{width: '20%', textAlign: 'center'}}>{translate('referral.statistics.date_registration')}</Typography>
          <Typography style={{width: '20%', textAlign: 'center'}}>{translate('referral.statistics.deposit')}</Typography>
          <Typography style={{width: '20%', textAlign: 'center'}}>{translate('referral.statistics.withdrawals')}</Typography>
          <Typography style={{width: '20%', textAlign: 'center'}}>{translate('referral.statistics.profit')}</Typography>
        </div>
        <div>
          {
            list?.list?.map((item) => (
              <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff'}}>
                <Typography style={{width: '20%', textAlign: 'center'}}>{item.login}</Typography>
                <Typography style={{width: '20%', textAlign: 'center'}}>{format(new Date(+item.registration), 'dd.MM.yyyy')}</Typography>
                <Typography style={{width: '20%', textAlign: 'center'}}>{item?.sum_deposit}</Typography>
                <Typography style={{width: '20%', textAlign: 'center'}}>{item?.sum_withdrawals}</Typography>
                <Typography style={{width: '20%', textAlign: 'center'}}>{(item?.sum_deposit - item?.sum_withdrawals) * referralProfile?.percent/100}</Typography>
              </div>
            ))
          }
        </div>
        {
          Number(list?.page?.currentPage) !== list?.page?.allPage && (
            <Button 
              variant="contained" 
              style={{width: '100%', marginTop: 20}}
              onClick={() => {
                dispatch(setGetListReferral({
                  page: page + 1,
                  rowsPerPage: 20
                }));
                setPage(page + 1)
              }}
            >{translate('common.more_load')}</Button>
          )
        }
      </div>
    </>
  )
}

export default ReferralDesktop;