/* eslint-disable */
import {postAuthorization} from '../api/postAuthorization';
import {setAuthorizeAction, setProfileAction} from '../action';
import { isFunction } from 'lodash';

export const setPostAuthorization = (params, setIsShowAuth, setIsOpen, setError) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postAuthorization(params);

            if (data.status === 'success') {
                window.localStorage.setItem('token', data.token);
                dispatch(setAuthorizeAction(true));
                dispatch(setProfileAction(data.profile));
                if (isFunction(setIsShowAuth)) {
                    setIsShowAuth(false);
                }
                if (isFunction(setIsOpen)) {
                    setIsOpen(false);
                }
                if (params?.redirect) {
                    window.location.replace(window.location.origin);   
                }
            }
        } catch(error) {
            setError(true)
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}