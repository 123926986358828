/* eslint-disable */
import { types } from './types';

export const setGetTransactionAction = (payload) => {
  return {
    type: types.SET_GET_TRANSACTION,
    payload,
  }
}

export const setGetCardAction = (payload) => {
  return {
    type: types.SET_GET_CARD,
    payload,
  }
}

export const setGetReplenishmentTransactionAction = (payload) => {
  return {
    type: types.SET_GET_REPLENISHMENT_TRANSACTION,
    payload
  }
}