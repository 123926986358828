import React from 'react';
import Loader from '../loader';
import { useRouter } from '../../../../components/hook/useRouter';
import {translate} from '../../../../dictionary';

const Modals = ({message, setShowModal}) => {
    const router = useRouter();
    if (message === 'fs issued') {
        setTimeout(() => {
            setShowModal(false);
            router.history.push('/#bonus')
        }, 10000);
    }

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.6)', zIndex: 100000, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ position: 'relative', background: '#fff', maxWidth: 300, width: '100%', borderRadius: 8, padding: 20, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                {
                    message === 'fs issued' && (
                        <>
                            <h3 style={{ fontFamily: "Montserrat, sans-serif", fontSize: 18, fontWeight: 700, }}>{translate('bonus.fs.modals.settings')}</h3>
                            <Loader />
                        </>
                    )
                }
                {
                    message === 'bonus already' && (
                        <>
                            <h3 style={{ fontFamily: "Montserrat, sans-serif", fontSize: 18, fontWeight: 700, }}>{translate('bonus.fs.modals.have_activate')}</h3>
                        </>
                    )
                }
                {
                    message === 'promcode not found' && (
                        <h3 style={{ fontFamily: "Montserrat, sans-serif", fontSize: 18, fontWeight: 700, }}>{translate('bonus.fs.modals.promocode_not_found')}</h3>
                    )
                }
                {
                    message === 'dublicate' && (
                        <h3 style={{ fontFamily: "Montserrat, sans-serif", fontSize: 18, fontWeight: 700, }}>
                            {translate('bonus.fs.modals.promocode_block_activate')}
                        </h3>
                    )
                }
                <div style={{position: 'absolute', top: 10, right: 10}} onClick={() => setShowModal(false)}>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                        <line x1="1.5" y1="-1.5" x2="18.4722" y2="-1.5" transform="matrix(0.713188 0.700973 -0.713188 0.700973 0 2)" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></line>
                        <line x1="1.5" y1="-1.5" x2="18.4722" y2="-1.5" transform="matrix(-0.713188 0.700973 0.713188 0.700973 16.2439 2)" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></line>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Modals;