import React from 'react';
import FS from '../assets/fs.png';
import { Typography } from '@mui/material';
import { useRouter } from '../../../../components/hook/useRouter';
import { translate } from '../../../../dictionary';

const FreespinStepOne = ({fs}) => {
    const router = useRouter();

    return (
        <div style={{ width: 350, height: 400, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5, background: 'linear-gradient(93deg, rgba(12,0,65,1) 0%, rgba(68,17,104,1) 50%, rgba(12,0,65,1) 100%)' }}>
            <div>
                <img src={FS} style={{ width: '100%' }} alt="" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                <Typography style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textAling: 'center', fontSize: 20, fontWeight: 700 }}>{translate('bonus.fs.step.count.bonus')} {fs?.quantity} {translate('bonus.fs.step.count.freespin')}</Typography>
            </div>
            <div style={{ display: 'flex', width: 200, margin: '40px auto 0' }}>
                <div
                    onClick={() => router.history.push(`/game/${fs?.game_uuid}`)} 
                    style={{ display: 'flex', padding: '10px 20px', background: '#FF3640', width: '100%', justifyContent: 'center', color: '#FFF', borderRadius: 20, cursor: 'pointer' }}>
                    <Typography>{translate('common.play')}</Typography>
                </div>
            </div>
        </div>
    )
}

export default FreespinStepOne;