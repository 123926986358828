import React from 'react';
import FS from '../assets/fs.png';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setPostPlayedCreate } from '../../../../store/bonuses/thunk/setPostPlayedCreate';
import {translate} from '../../../../dictionary';

const FreespinStepTwo = ({fs}) => {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.auth.profile)

    return (
        <div style={{ width: 350, height: 400, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5, background: 'linear-gradient(93deg, rgba(12,0,65,1) 0%, rgba(68,17,104,1) 50%, rgba(12,0,65,1) 100%)' }}>
            <div>
                <img src={FS} style={{ width: '100%' }} alt="" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, flexDirection: 'column', alignItems: 'center' }}>
                <Typography style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textAling: 'center', fontSize: 20, fontWeight: 700 }}>{translate('bonus.fs.step.bonus_from_freespin')}</Typography>
                <Typography style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textAling: 'center', fontSize: 28, fontWeight: 700 }}>{new Intl.NumberFormat('ru-RU').format(fs?.win_amount || 0)} {profile?.currency}</Typography>
            </div>
            <div style={{ display: 'flex', width: 200, margin: '20px auto 0' }}>
                <div 
                    onClick={() => {
                        dispatch(setPostPlayedCreate({
                            fs_id: fs?.id
                        }))
                    }}
                style={{ display: 'flex', padding: '10px 20px', background: '#FF3640', width: '100%', justifyContent: 'center', color: '#FFF', borderRadius: 20, cursor: 'pointer' }}>
                    <Typography>{translate('common.activate')}</Typography>
                </div>
            </div>
        </div>
    )
}

export default FreespinStepTwo;