/* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { ReactComponent as Balance } from '../../../desktop/assets/balance.svg';
import ChangePassword from '../changePassword';
import Navigation from './components/navigation';
import Statistics from '../statistic';
import Bonus from '../bonus';
import Referral from '../referral';
import Logo from './logo.jpg';
import Withdrawal from '../../../desktop/withdrawal';
import { useRouter } from '../../../components/hook/useRouter';
import CloseIcon from '@mui/icons-material/Close';
import { translate } from '../../../dictionary';
import ModalBonus from './components/ModalBonus';

const SideBar = ({
  isOpenReplenishment,
  setIsOpenReplenishment
}) => {
  const profile = useSelector((state) => state.auth.profile);
  const [isShowModalBonus, setIsShowModalBonus] = React.useState(false);
  const [isOpenWithdrawal, setIsOpenWithdrawal] = React.useState(false);


  const router = useRouter();

  return (
    <div className='search-games'>
      <CloseIcon style={{ color: '#fff', width: 50, height: 50, padding: 10, position: 'absolute', top: 0, right: 5 }}
        onClick={() => {
          router.history.push('/')
        }}
      />
      <div style={{ padding: 20, paddingTop: 50, display: 'flex', alignItems: 'center', columnGap: 20 }}>
        <img src={Logo} alt="logo" style={{ width: 130, borderRadius: 20, boxShadow: '4px 4px 14px 0px rgba(251, 251, 251, 0.2)' }} />
        <div style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff', fontWeight: 500, lineHeight: '25px' }}>
          <div>
            <span>ID: </span>
            <span>{profile?.id}</span>
          </div>

          <div style={{ marginBottom: 10 }}>
            <span>{translate('signin.login2')}: </span>
            <span>{profile?.login}</span>
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{
              borderTopLeftRadius: 6,
              borderBottomLeftRadius: 6,
              background: '#A60C65',
              padding: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Balance />
            </div>
            <div style={{
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6,
              background: '#CF0E7E',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 5,
              fontFamily: 'Montserrat, sans-serif',
              color: '#ffffff',
              fontWeight: 600
            }}>
              {new Intl.NumberFormat('ru-RU').format(profile?.balance?.toFixed(2) || 0) + profile?.currency}
            </div>
          </div>
          {
            Boolean(profile?.wager) && (
              <div style={{ display: 'flex', marginTop: 12 }}>
                <div style={{
                  borderTopLeftRadius: 6,
                  borderBottomLeftRadius: 6,
                  background: '#A60C65',
                  padding: 5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  WAGER
                </div>
                <div style={{
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                  background: '#CF0E7E',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 5,
                  fontFamily: 'Montserrat, sans-serif',
                  color: '#ffffff',
                  fontWeight: 600
                }}>
                  {new Intl.NumberFormat('ru-RU').format(profile?.wager?.toFixed(2) || 0) + profile?.currency}
                </div>
              </div>
            )
          }
        </div>
      </div>
      <Route exact path="/user/" component={() => <Navigation setIsShowModalBonus={setIsShowModalBonus} isShowModalBonus={isShowModalBonus} profile={profile} setIsOpenReplenishment={setIsOpenReplenishment} setIsOpenWithdrawal={setIsOpenWithdrawal} />} />
      <Route exact path="/user/referral" component={() => <Referral profile={profile} />} />
      <Route exact path="/user/statistic" component={() => <Statistics />} />
      <Route exact path="/user/password" component={() => <ChangePassword />} />
      <Route exact path="/user/bonus" component={() => <Bonus />} />
      <Withdrawal setIsOpenWithdrawal={setIsOpenWithdrawal} isOpenWithdrawal={isOpenWithdrawal} profile={profile} mobile />
      <ModalBonus isOpen={isShowModalBonus} onClose={setIsShowModalBonus} />
    </div>
  )
}

export default SideBar;