/* eslint-disable */
import {types} from './types';

const initialState = {
    list: []
}

export function jackpotReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_JACKPOT_LSIT:
            return { ...state, list: action.payload};
        default:
            return state
    }
}