/* eslint-disable */
import { combineReducers } from 'redux'
import { authReducer } from '../authorization/reducer';
import { gamesReducer } from '../games/reducer';
import { referralReducer } from '../referral/reducer';
import { transactionReducer } from '../transaction/reducer';
import { jackpotReducer } from '../jackpot/reducer';

export const rootReducer = (history) => {
  return combineReducers({
    auth: authReducer,
    transaction: transactionReducer,
    referral: referralReducer,
    games: gamesReducer,
    jackpot: jackpotReducer
})}