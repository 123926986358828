import React from 'react';
import FS from '../assets/fs.png';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setPostPlayedConfirm } from '../../../../store/bonuses/thunk/setPostPlayedConfrim';
import {translate, i18n} from '../../../../dictionary';

const FreespinStepFour = ({fs}) => {
    const dispatch = useDispatch();
    const profile = useSelector(state => state.auth.profile)
    return (
        <div style={{ position: 'relative', width: 350, height: 400, boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5, background: 'linear-gradient(93deg, rgba(12,0,65,1) 0%, rgba(68,17,104,1) 50%, rgba(12,0,65,1) 100%)' }}>
            <div>
                <img src={FS} style={{ width: '100%' }} alt="" />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 0, flexDirection: 'column', alignItems: 'center' }}>
                <Typography style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textAling: 'center', fontSize: 20, fontWeight: 700 }}>{translate('bonus.fs.step.bonus_from_freespin')}</Typography>
                <Typography style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textAling: 'center', fontSize: 28, fontWeight: 700 }}>{new Intl.NumberFormat('ru-RU').format(fs?.amount)} {profile?.currency}</Typography>
            </div>
            <div style={{ margin: '0 20px' }}>
                <Typography style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textAling: 'center', fontSize: 12, fontWeight: 700 }}>
                    {
                        i18n({
                            wager: fs?.wager_count,
                        }, 'bonus.fs.step.wager')
                    }
              </Typography>
            </div>
            <div style={{ display: 'flex', position: 'absolute', justifyContent: 'space-between', width: 'calc(100% - 20px)', bottom: 10, margin: '0 10px' }}>
                <Typography style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textAling: 'center', fontSize: 12, fontWeight: 700 }}>
                    {new Intl.NumberFormat('ru-RU').format(fs?.wager_played)}
              </Typography>
                <Typography style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textAling: 'center', fontSize: 12, fontWeight: 700 }}>
                    {new Intl.NumberFormat('ru-RU').format(fs?.wager)}
                </Typography>
            </div>
            <div style={{ position: 'absolute', bottom: 0, left: 0, width: 350 }}>
                <div style={{ width: '100%', height: 5, background: '#FFFFFF', position: 'relative' }}>
                    <div style={{ width: `${fs?.wager_played / fs?.wager * 100}%`, height: 5, background: '#FF2628', position: 'absolute', top: 0, left: 0 }} />
                </div>
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0, background: '#FF3640', padding: 5, borderBottomLeftRadius: 5 }}>
                <Typography style={{ color: '#FFFFFF', fontFamily: 'Montserrat, sans-serif', textAling: 'center', fontSize: 12, fontWeight: 700 }}>2дн. 18:29:30</Typography>
            </div>
            <div style={{ width: 350, height: 400, backdropFilter: 'blur(4px)', position: 'absolute', top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div 
                    onClick={() => dispatch(setPostPlayedConfirm({fs_played_id: fs?.id}))}
                    style={{ display: 'flex', padding: '10px 20px', background: '#FF3640', width: '50%', justifyContent: 'center', color: '#FFF', borderRadius: 20, cursor: 'pointer' }}>
                    <Typography>{translate('common.claim_bonus')}</Typography>
                </div>
            </div>
        </div>
    )
}

export default FreespinStepFour;