import {postRegistration} from '../api/postRegistration';
import { setPostAuthorization } from './setPostAuthorization';
import { translate } from '../../../dictionary';

export const setPostRegistration = (params, setShowModalConfirm, setErrorRegistration) => {
    return async (dispatch, getState) => {
        try {
            params.organization = 1;
            params.utm_source = localStorage.getItem('utm_source');
            params.utm_term = localStorage.getItem('utm_term');
            params.utm_medium = localStorage.getItem('utm_medium');
            params.utm_campaign = localStorage.getItem('utm_campaign');
            params.utm_content = localStorage.getItem('utm_content');
            params.activeBonus = 'alldeposit';

            const {data} = await postRegistration(params);

            if (data.status === 'success') {
                dispatch(setPostAuthorization({
                    login: params.login,
                    password: params.password,
                    redirect: true
                }));
            }
        } catch(error) {
            if (error?.response?.data?.message === 'user is already') {
                if (setErrorRegistration) {
                    setErrorRegistration(true);
                } 
            } else {
                alert(translate('signin.success_registration'))
                window.location.replace(window.location.origin)
            }
            if (error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}