/* eslint-disable */
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGetTransaction } from '../../../store/transaction/thunk/setGetTransaction';
import { format } from 'date-fns';
import { ReactComponent as Back } from '../../../desktop/assets/back.svg';
import { setGetTransactionAction } from '../../../store/transaction/action';
import { useRouter } from '../../../components/hook/useRouter';
import {translate} from '../../../dictionary';

const Statistic = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    dispatch(setGetTransaction({
      page: 1,
      rowsPerPage: 20
    }))
  }, []);

  const list = useSelector((state) => state.transaction.list);
  const router = useRouter();
  let navigate = (value) => router.history.push(value)

  return (
    <>
      <div style={{
        background: 'rgba(255, 255, 255, 0.12)',
        backdropFilter: 'blur(19.5px)',
        margin: '20px',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        rowGap: '10px',
        padding: '20px',
      }}>
        <div 
          style={{display: 'flex', alignItems: 'center', position: 'absolute', top: -35, right: 10}} 
          onClick={() => {
            navigate('/user');
            dispatch(setGetTransactionAction({transaction: [], count: 0}))
          }}
        >
          <Back style={{width: 30, height: 30, fill: '#fff'}}/>
          <Typography style={{color: '#fff'}}>{translate('common.back')}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Typography style={{ color: '#fff' }}>{translate('transaction.title')}</Typography>
        </div>

      </div>
      <div style={{
        background: 'rgba(255, 255, 255, 0.12)',
        backdropFilter: 'blur(19.5px)',
        margin: '20px',
        borderRadius: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        rowGap: '10px',
        padding: '20px',
      }}>
       
      {
        list.transaction.map((item) => (
          <div style={{ width: '100%', color: item.tag === "replenishment" ? '#34eb71' : '#ab3236', display: 'flex', columnGap: 12, justifyContent: 'space-between' }}>
            <Typography>
              {item.tag.toString().toLowerCase() === 'replenishment' ? '+ ' : '- '}
              {new Intl.NumberFormat('ru-RU').format(Math.trunc(item.summ))} {item.currency}
            </Typography>
            <Typography>{format(new Date(Number(item?.event_date)), 'dd.MM.yyyy hh:mm')}</Typography>
          </div>
        ))
      }
      {
        page !== list.count && (
          <Button 
            variant="contained" 
            style={{width: '100%', marginTop: 20}}
            onClick={() => {
              dispatch(setGetTransaction({
                page: page + 1,
                rowsPerPage: 20
              }));
              setPage(page + 1)
            }}
          >{translate('common.more_load')}</Button>
        )
      }

      </div>
    </>

  )
}

export default Statistic;