/* eslint-disable */
import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGetGamesList } from '../../../store/games/thunk/setGetGamesList';
import { useRouter } from '../../../components/hook/useRouter';
import {translate} from '../../../dictionary';

const Games = ({setIsShowAuth, page, setPage, search, providerSelect}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setGetGamesList({
      page: 1,
      type: 'slots',
      provider: providerSelect,
      search: search,
      is_desktop: 0,
      is_mobile: 1
    }))
  }, []);

  const games = useSelector((state) => state.games.list);
  const router = useRouter();
  const navigate = (value) => router.history.push(value);

  return (
    <div>
      <div className='games-root'>
        {
          games?.list.map((item) => {
            return (
              <img key={item.uuid} src={item.image} alt="" onClick={() => {
                if (localStorage.getItem('token')) {
                  navigate('/game/'+item.uuid); 
                } else {
                  setIsShowAuth(true);
                }
              }} style={{ width: '48%', minHeight: 110, borderRadius: 12, boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.4)" }} />
            )
          })
        }
        {
          !games?.list?.length && [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map((item) => {
            return (
              <div style={{ width: '48%',  height: 110 , borderRadius: 12, boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.4)" }} />
            )
          })
        }
        <Button
          variant="contained"
          style={{ width: '100%', marginTop: 20 }}
          onClick={() => {
            dispatch(setGetGamesList({
              page: page + 1,
              type: 'slots',
              provider: providerSelect,
              search: search,
              is_desktop: 0,
              is_mobile: 1
            }));
            setPage(page + 1)
          }}
        >{translate('common.more_load')}</Button>
      </div>
    </div>
  )
}

export default Games;