/* eslint-disable */
import { postPlayedConfirm } from '../api/postPlayedConfirm';
import { setGetAuthorization } from '../../authorization/thunk/setGetAuthorization';

export const setPostPlayedConfirm = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postPlayedConfirm(params);
            dispatch(setGetAuthorization());
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.clear();
                window.location.replace(window.location.origin);
            }
        }
    }
}