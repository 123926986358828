/* eslint-disable */
import React from 'react';
import { Route } from 'react-router';
import Account from './account';
import Game from './game';
import Header from './header';
import Home from './home';
import Rules from './rules';

const Desktop = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenReplenishment, setIsOpenReplenishment] = React.useState(false);

  return (
    <div className='desktop-root'> 
      <Header isOpen={isOpen} setIsOpen={setIsOpen} isOpenReplenishment={isOpenReplenishment} setIsOpenReplenishment={setIsOpenReplenishment} />
      <Route path="/account/" component={() => <Account />} />
      <Route path="/rules" component={() => <Rules />} />
      <Route exact={true} path="/game/:id" component={() => <Game />} />
      <Route exact={true} path="/" component={() => <Home setIsOpen={setIsOpen} isOpenReplenishment={isOpenReplenishment} setIsOpenReplenishment={setIsOpenReplenishment} />} />
    </div>
  )
}

export default Desktop;