/* eslint-disable */

import { Typography } from '@mui/material';
import React from 'react';
import { Route } from 'react-router';
import Footer from '../footer';
import ChangePasswordDesktop from './password';
import ReferralDesktop from './referral/index.jsx';
import Transaction from './transaction';
import { useRouter } from '../../components/hook/useRouter';
import {translate} from '../../dictionary';

const Account = () => {
  const [isActive, setIsActive] = React.useState('referral');
  const router = useRouter();
  const {pathname} = router;

  const navigate = (value) => router.history.push(value);

  return (
    <div style={{ background: '#1B2036', position: 'relative', minHeight: 'calc(100vh - 220px)' }}>
      <div style={{ maxWidth: 1140, margin: '0 auto', marginTop: 100, display: 'flex', columnGap: 20, justifyContent: 'space-between',}}>
        <div style={{ width: 200, minWidth: 200, height: 230, background: '#20253B', boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', position: 'relative', paddingTop: 50 }}>
          <div>
            <Typography
              onClick={() => {
                setIsActive('transaction');
                navigate("/account/transaction");
              }}
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, padding: '10px 30px', cursor: 'pointer', color: pathname === '/account/transaction' ? '#FFFFFF' : '#757B96', borderLeft: pathname === '/account/transaction' ? '2px solid #F7BB18' : 'none' }}>
              {translate('navigation.transaction')}
            </Typography>
          </div>
          <div>
            <Typography
              onClick={() => {
                setIsActive('referral');
                navigate("/account/referral");
              }}
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, padding: '10px 30px', cursor: 'pointer', color: pathname === '/account/referral' ? '#FFFFFF' : '#757B96', borderLeft: pathname === '/account/referral' ? '2px solid #F7BB18' : 'none' }}>
              {translate('navigation.referral_program')}
            </Typography>
          </div>
          <div>
            <Typography
              onClick={() => {
                setIsActive('password');
                navigate("/account/password");
              }}
              style={{ fontFamily: "Montserrat, sans-serif", fontSize: 16, padding: '10px 30px', cursor: 'pointer', color: pathname === '/account/password' ? '#FFFFFF' : '#757B96', borderLeft: pathname === '/account/password' ? '2px solid #F7BB18' : 'none' }}>
              {translate('navigation.change_password')}
            </Typography>
          </div>
        </div>
        <div style={{marginBottom: 100}}>
          <Route path="/account/transaction" component={() => <Transaction />} />
          <Route path="/account/password" component={() => <ChangePasswordDesktop />} />
          <Route path="/account/referral" component={() => <ReferralDesktop />} />
        </div>
      </div>
      <div style={{position: 'absolute', bottom: 0, left: 0, width: '100%'}}>
        <Footer />
      </div>

    </div>
  )
}

export default Account;