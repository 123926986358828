/* eslint-disable */
import { Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as Present } from './present.svg';
import { translate } from '../../dictionary';
import WelcomeBonus from './components/WelcomeBonus';

const Bonuses = ({
    setIsOpenReplenishment,
    setIsShowAuth
}) => {
    const [isShowModalWelcomeBonus, setIsShowModalWelcomeBonus] = React.useState(false);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 12, justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Present />
                        <Typography
                            style={{ fontFamily: "Montserrat, sans-serif", fontSize: 18, fontWeight: 400, padding: '0 10px 10px', cursor: 'pointer', paddingTop: 13, color: '#fff' }}
                        >
                            {translate('bonus.title')}
                        </Typography>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', rowGap: 10, marginBottom: 100 }}>
                    <div style={{position: 'relative'}}>
                        <img
                            src="/assets/bonus/first_1.jpg"
                            alt=""
                            style={{ boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5, width: 350, height: 400 }}
                        />
                        <div style={{position: 'absolute', bottom: 65, left: 20}}>
                            <span style={{
                                 fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                 fontSize: 36,
                                 fontWeight: 900,
                                 color: '#FFF',
                                 lineHeight: 1.1,
                                 padding: 0,
                                 margin: 0,
                            }}>Бонус <span style={{
                                color: '#FEF723',
                                backgroundImage: 'linear-gradient(99.83deg, #FEF723 -48.63%, #ff9815 97.54%)',
                                backgroundClip: 'text'
                            }}>+500%</span><br/> на депозит</span>
                        </div>
                        <div style={{position: 'absolute', bottom: 15, left: 20}}>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }} onClick={() => setIsShowModalWelcomeBonus(true)}>
                                <div style={{
                                    backgroundImage: 'linear-gradient(99.83deg,#fdc63d -48.63%,#ff9815 97.54%)',
                                    boxShadow: '0 12px 24px #ffa62159',
                                    borderRadius: 10,
                                    padding: '0 35px',
                                    height: 40,
                                    fontWeight: 700,
                                    fontSize: 14,
                                    lineHeight: 1.25,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                    fontSize: 16,
                                    color: '#FFFFFF',
                                    cursor: 'pointer'
                                }}>
                                <span>Получить бонус</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <img
                        src="/assets/bonus/cashback.jpg"
                        alt=""
                        style={{ boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.2)', borderRadius: 5, width: 350, height: 400 }}
                    />
                </div>
            </div>
            {
                isShowModalWelcomeBonus && (
                    <WelcomeBonus 
                        setIsShowModalWelcomeBonus={setIsShowModalWelcomeBonus} 
                        isShowModalWelcomeBonus={isShowModalWelcomeBonus} 
                        setIsOpenReplenishment={setIsOpenReplenishment}
                        setIsShowAuth={setIsShowAuth} 
                    />
                )
            }

        </div>
    )
}

export default Bonuses;