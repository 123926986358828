/* eslint-disable */
import { types } from './types';

export const setGetGamesListAction = (payload) => {
  return {
    type: types.SET_GAMES_LIST,
    payload,
  }
}

export const setGetSearchGamesAction = (payload) => {
  return {
    type: types.SET_SEARCH_GAMES_LIST,
    payload
  }
}


export const setGamesAction = (payload) => {
  return {
    type: types.SET_GAMES,
    payload,
  }
}

export const setGameIdAction = (payload) => {
  return {
    type: types.SET_GAME_ID,
    payload,
  }
}

export const setGameAction = (payload) => {
  return {
    type: types.SET_GAME,
    payload
  }
}

export const setGetProviderListAction = (payload) => {
  return {
    type: types.SET_PROVIDER,
    payload
  }
}