/* eslint-disable */
import React from 'react';
import Banner from '../banner';
import Bonuses from '../bonuses';
import Footer from '../footer';
import Popular from '../popular';
import Slot from '../slot';
import Jackpot from '../jackpot';
import { ReactComponent as Whatsapp } from '../../components/img/whatsapp.svg';
import { translate } from '../../dictionary';

const Home = ({ setIsOpen, setIsOpenReplenishment }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Banner />
      <Jackpot />
      <Popular setIsOpen={setIsOpen} />
      <Slot setIsOpen={setIsOpen} />
      <Bonuses setReplenishment={setIsOpenReplenishment} setIsOpen={setIsOpen} />
      <Footer />
      <div style={{ position: 'fixed', bottom: 20, left: 20, zIndex: 100, width: 50, cursor: 'pointer' }}>
        <div style={{ position: 'relative' }}>
          <div style={{ padding: 10, color: '#000', width: 100, position: 'absolute', top: -80, left: 40, borderTopLeftRadius: 8, borderTopRightRadius: 8, borderBottomRightRadius: 8, background: '#fff' }}>
            {translate('common.support')}
          </div>
          <Whatsapp
            style={{ position: 'fixed', bottom: 20, left: 20, zIndex: 100, width: 50, cursor: 'pointer' }}
            onClick={() => window.location.replace('https://wa.me/79166578649')}
          />
        </div>
      </div>
    </div>
  )
}

export default Home;