import React from 'react';
import { Dialog, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setReplenishmentCreate } from '../../store/transaction/thunk/setReplenishmentCreate';
import { translate } from '../../dictionary';
import { useSelector } from 'react-redux';
import { ReactComponent as Sber } from '../assets/ru_sber.svg';
import { ReactComponent as Copy } from '../assets/copy.svg';
import { setGetCard } from '../../store/transaction/thunk/setGetCard';
import { setPostCreateTransaction } from '../../store/transaction/thunk/setPostCreateTransaction';
import { setGetReplenishmentTransaction } from '../../store/transaction/thunk/setGetReplenishmentTrasaction';
import copy from 'clipboard-copy';
import { setPostCancelTransaction } from '../../store/transaction/thunk/setPostCancelTransaction';
import { ReactComponent as CardIcon } from './card.svg';
import { ReactComponent as Cross } from './cross.svg';

const Replenishment = ({ setIsOpenReplenishment, isOpenReplenishment, mobile }) => {
    const dispatch = useDispatch();
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const token = localStorage.getItem('token');

    React.useEffect(() => {

        if (token) {
            dispatch(setGetCard());
            dispatch(setGetReplenishmentTransaction());
        }

    }, [token]);

    const loadBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const { register, handleSubmit, control, setValue } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            amount: 300,
            check: {},
            step: 1
        }
    });

    const [amount, check] = useWatch({
        control,
        name: ['amount', 'check']
    })


    const profile = useSelector(state => state.auth.profile);
    const card = useSelector((state) => state.transaction.card);
    const transaction = useSelector((state) => state.transaction.transaction);

    const onSubmit = (store) => {
        setIsLoading(true)
        const callbackSuccess = () => {
            setIsSuccess((value) => !value);
            setIsLoading((value) => !value);
        }
        const formData = new FormData();
        formData.append('sum', transaction.amount);
        formData.append('card', card.card);
        formData.append('file', store.check.file)
        formData.append('transaction', transaction.bill_id);

        dispatch(setPostCreateTransaction(formData, callbackSuccess));
    }


    const handleCopyCard = () => {
        copy(card.card);
    };

    const handleCopySum = () => {
        copy(transaction.amount);
    };

    const now = new Date().getTime();
    const diff = +(Number(transaction?.data) + 900000) - now;

    const getDate = (val) => {
        const now = new Date().getTime();
        const diff = +val - now;

        let hours = (diff / 1000) / 60 / 60;
        let minutes = (diff - Math.floor(hours) * 1000 * 60 * 60) / 1000 / 60;
        let seconds = (diff - (Math.floor(hours) * 1000 * 60 * 60 + Math.floor(minutes) * 1000 * 60)) / 1000;

        return `${Math.floor(minutes) < 10 ? '0' + Math.floor(minutes) : Math.floor(minutes || 0)} мин ${Math.round(seconds) < 10 ? '0' + Math.round(seconds) : Math.round(seconds || 0)} сек`;
    }

    const [left, setLeft] = React.useState(1);

    let timer = null;

    React.useEffect(() => {
        timer = setInterval(() => {
            if (left < 10) {
                setLeft(left + 1);
            } else {
                setLeft(1)
            }
        }, 1000);

        if (diff < 0) {
            dispatch(setGetReplenishmentTransaction());
        }
        return () => {
            clearInterval(timer);
        }
    }, [left]);

    return (
        <Dialog open={isOpenReplenishment} onClose={() => setIsOpenReplenishment(false)}>
            <div style={{ background: '#FFFFFF', width: 300, height: '100%', padding: '20px 20px 20px 20px', overflowX: 'hidden', borderRadius: 10 }}>
                <div style={{ textAlign: 'center' }}>
                    <span style={{
                        fontFamily: '-apple-system,SF Pro Text,sans-serif',
                        fontSize: 16,
                        fontWeight: 700,
                        color: '#090f1e',
                        lineHeight: 1.1,
                        padding: 0,
                        margin: 0,
                    }}>Пополнение</span>
                </div>
                <div onClick={() => {
                    setIsOpenReplenishment(false);
                }} style={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    minWidth: 24,
                    width: 24,
                    height: 24,
                    background: '#edf0f7',
                    borderRadius: 6,
                    position: 'absolute',
                    right: 10,
                    top: 10
                }}>
                    <Cross style={{ width: 9, height: 9 }} />
                </div>
                {
                    profile?.currency !== 'RUB' && (
                        <div style={{ padding: '0 10px', textAlign: 'center', marginTop: 20 }}>
                            <Typography color="white" style={{ fontSize: 18 }}>{translate('payment')}</Typography>
                        </div>
                    )
                }
                {
                    transaction?.id && !isSuccess && (
                        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', marginTop: 30 }}>
                            <div style={{ display: 'flex', columnGap: 10 }}>
                                <div style={{ width: 310, height: 130, background: 'rgb(26, 147, 41)', padding: 20, borderRadius: 14, flexDirection: 'column', display: 'flex', rowGap: 20 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Sber />
                                        <Typography style={{ color: '#FFF', cursor: 'pointer', fontSize: 12 }} onClick={() => {
                                            dispatch(setPostCancelTransaction({
                                                transaction: transaction.bill_id
                                            }, () => {
                                                dispatch(setGetReplenishmentTransaction());
                                            }))
                                        }}>отменить</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
                                                <Typography style={{ color: '#FFF', opacity: 0.7, fontSize: 10, lineHeight: '11px' }}>Сумма для перевода</Typography>
                                                <Typography style={{ color: '#FFF', fontWeight: 700, fontSize: 16, lineHeight: '20px' }}>{new Intl.NumberFormat('ru-RU').format(transaction.amount)} ₽</Typography>
                                            </div>
                                            <div>
                                                <div onClick={handleCopySum} style={{ cursor: 'pointer', width: 24, height: 24, borderRadius: 6, background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Copy style={{ width: 12, height: 12 }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
                                                <Typography style={{ color: '#FFF', opacity: 0.7, fontSize: 10, lineHeight: '11px' }}>Номер карты для перевода</Typography>
                                                <Typography style={{ color: '#FFF', fontWeight: 700, fontSize: 16, lineHeight: '20px' }}>{card?.card ? `${card.card.slice(0, 4)} ${card.card.slice(4, 8)} ${card.card.slice(8, 12)} ${card.card.slice(12)}` : '-'}</Typography>
                                            </div>
                                            <div>
                                                <div onClick={handleCopyCard} style={{ cursor: 'pointer', width: 24, height: 24, borderRadius: 6, background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Copy style={{ width: 12, height: 12 }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                !check.file && (
                                    <div style={{ display: 'flex', marginTop: 15, alignItems: 'center', flexDirection: 'column' }}>
                                        <span style={{
                                            fontSize: 12,
                                            fontWeight: 600,
                                            color: '#090f1e',
                                            lineHeight: 1,
                                            marginBottom: 2,
                                            fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                        }}>{getDate(Number(transaction.data) + 900000)} <br /> Для выполнения операции необходимо загрузить чек </span>
                                        <label htmlFor='attach' style={{ display: 'flex', marginTop: 20 }}>
                                            <div style={{ padding: '5px 10px', background: 'rgb(26, 147, 41)', borderRadius: 8, cursor: 'pointer' }}>
                                                <Typography style={{ color: '#FFF', fontWeight: 700, fontSize: 16, lineHeight: '20px' }}>Загрузить чек</Typography>
                                            </div>

                                            <input
                                                type="file"
                                                id="attach"
                                                style={{ width: 0, height: 0, display: 'none' }}
                                                onChange={async (e) => {
                                                    const file = e.target.files?.[0];
                                                    const base64 = await loadBase64(file);

                                                    setValue('check', {
                                                        file,
                                                        base64
                                                    })
                                                }}
                                            />
                                        </label>
                                    </div>
                                )
                            }
                            {
                                check.file && (
                                    <div style={{ display: 'flex', marginTop: 15, alignItems: 'center', flexDirection: 'column', rowGap: 10, textAlign: 'center' }}>
                                        <span style={{
                                            fontSize: 13,
                                            fontWeight: 600,
                                            color: '#090f1e',
                                            lineHeight: 1,
                                            marginBottom: 2,
                                            fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                        }}>Для завершения операции<br /> нажмите кнопку</span>
                                        {
                                            isLoading && (
                                                <CircularProgress color="secondary" />
                                            )
                                        }
                                        {
                                            !isLoading && (
                                                <button type="submit" style={{ padding: '5px 10px', background: 'rgb(26, 147, 41)', borderRadius: 8, cursor: 'pointer', border: 'none', outline: 'none' }}>
                                                    <Typography style={{ color: '#FFF', fontWeight: 700, fontSize: 16, lineHeight: '20px' }}>Я оплатил</Typography>
                                                </button>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                Boolean(check.base64) && (
                                    <img src={check?.base64} style={{ width: 50, height: 50, borderRadius: 8, objectFit: 'cover' }} />
                                )
                            }
                        </form>
                    )
                }


                {
                    profile?.currency === "RUB" && !transaction?.id && !isSuccess && (
                        <div autoComplete="off" style={{ width: '100%', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8, width: '100%' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: 14,
                                        height: 46,
                                        padding: '0 15px',
                                        borderRadius: 12,
                                        backgroundColor: '#94a6cd33',
                                        width: 'calc(100% - 30px)',
                                        display: 'flex',
                                        columnGap: 12,

                                    }}
                                >
                                    <CardIcon style={{ width: 50, height: 25 }} />
                                    <div>
                                        <span
                                            style={{
                                                fontSize: 13,
                                                fontWeight: 600,
                                                color: '#090f1e',
                                                lineHeight: 1,
                                                marginBottom: 2,
                                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                            }}
                                        >Банковская карта</span>
                                    </div>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: 14,
                                    height: 46,
                                    padding: '0 15px',
                                    borderRadius: 12,
                                    backgroundColor: '#94a6cd33',
                                    width: 'calc(100% - 30px)',
                                    position: 'relative'
                                }}>
                                    <input {...register('amount')} type="number" name="" id="" style={{ width: '100%', height: 46, border: 'none', outline: 'none', background: 'none', color: '#090f1e', fontFamily: '-apple-system,SF Pro Text,sans-serif', }} />
                                    <div style={{ position: 'absolute', bottom: -15, left: 0, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{
                                            fontSize: 11,
                                            fontWeight: 400,
                                            lineHeight: '13px',
                                            color: '#6a7690a6',
                                            marginTop: 5,
                                            fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                        }}>Лимит одного депозита</span>
                                        <span style={{
                                            fontSize: 11,
                                            fontWeight: 400,
                                            lineHeight: '13px',
                                            color: '#6a7690a6',
                                            marginTop: 5,
                                            fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                        }}>300 - 300 000 ₽</span>
                                    </div>

                                </div>
                            </div>
                            <div style={{ display: 'flex', columnGap: 8, marginTop: 35 }}>
                                <div
                                    style={{
                                        backgroundColor: '#edf0f7',
                                        borderRadius: 8,
                                        color: '#070c1980',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        marginBottom: 7,
                                        padding: '8px 10px',
                                        textAlign: 'center',
                                        fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                    }}
                                    onClick={() => setValue('amount', 300)}
                                >
                                    <span>300 ₽</span>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: '#edf0f7',
                                        borderRadius: 8,
                                        color: '#070c1980',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        marginBottom: 7,
                                        padding: '8px 10px',
                                        fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                    }}
                                    onClick={() => setValue('amount', 1000)}
                                >
                                    <span>1 000 ₽</span>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: '#edf0f7',
                                        borderRadius: 8,
                                        color: '#070c1980',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        marginBottom: 7,
                                        padding: '8px 10px',
                                        fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                    }}
                                    onClick={() => setValue('amount', 2000)}
                                >
                                    <span>2 000 ₽</span>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: '#edf0f7',
                                        borderRadius: 8,
                                        color: '#070c1980',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        marginBottom: 7,
                                        padding: '8px 10px',
                                        fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                    }}
                                    onClick={() => setValue('amount', 5000)}
                                >
                                    <span>5 000 ₽</span>
                                </div>
                            </div>
                            <div style={{ width: '100%', marginTop: 20 }}>
                                <div style={{
                                    background: 'linear-gradient(267.05deg, #089e4e 0%, #31bc69 99.28%)',
                                    boxShadow: '0 6px 18px #0ea1514d',
                                    textShadow: '0 3px 6px rgba(0, 0, 0, .2)',
                                    borderRadius: 12,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: 13,
                                    fontWeight: 600,
                                    height: 36,
                                    minWidth: 36,
                                    overflow: 'hidden',
                                    padding: '0 15px',
                                    position: 'relative',
                                    textAlign: 'center',
                                    textDecoration: 'none',
                                    transition: 'all .2s',
                                    '-webkit-user-select': 'none',
                                    '-moz-user-select': 'none',
                                    userSelect: 'none',
                                    fontFamily: '-apple-system,SF Pro Text,sans-serif',
                                    width: 'calc(100% - 30px)',
                                    color: '#FFF',
                                }}
                                    onClick={() => {
                                        if (amount >= 300) {
                                            dispatch(setReplenishmentCreate({
                                                amount
                                            }, () => {
                                                dispatch(setGetReplenishmentTransaction());
                                            }));
                                        }
                                    }}
                                >
                                    <span>Пополнить</span>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    isSuccess && (
                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                            <span style={{
                                fontSize: 14,
                                fontWeight: 600,
                                color: '#090f1e',
                                lineHeight: 1,
                                marginBottom: 2,
                                fontFamily: '-apple-system,SF Pro Text,sans-serif',
                            }}>Операция создана. Ожидайте пополнения баланса</span>
                        </div>
                    )
                }

            </div>
        </Dialog>
    )
}

export default Replenishment;