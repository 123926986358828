/* eslint-disable */
import React from 'react';
import { Route, useLocation } from 'react-router';
import Auth from './components/auth';
import Banner from './components/banner';
import Game from './components/game';
import Games from './components/games';
import Header from './components/header';
import Navigation from './components/navigation';
import SearchGames from './components/search';
import SideBar from './components/sidebar';
import './auth.css';
import './content.css';
import './desktop.css';
import './games.css';
import Background from './background.svg'
import Tournament from './components/tournament';
import Jackpot from './components/jackpot';
import Bonuses from './bonuses';
import Replenishment from '../desktop/replenishment';

const Mobile = () => {
  const [isShowAuth, setIsShowAuth] = React.useState(false);
  const [isOpenReplenishment, setIsOpenReplenishment] = React.useState(false);

  const token = localStorage.getItem('token');
 
  const location = useLocation();
  const [page, setPage] = React.useState(1);
  const [providerSelect, setProviderSelect] = React.useState('');
  const [search, setSearch] = React.useState('');
  
  return (
      <div style={{background: 'linear-gradient(171.51deg,#0e0230,#100233 96.09%)'}}>
        <div style={{ position: 'relative', overflow: 'scroll', backgroundImage: `url(./${Background})`, backgroundPositionY: 50, backgroundPositionX: -135, backgroundRepeat: 'no-repeat', backdropFilter: 'blur(19.5px)' }}>
      {
        location.pathname.indexOf('/game/') >= 0 && (
          <Route path="/game/:id" component={() => <Game />} />
        )
      }
      {
        location.pathname.indexOf('/game/') === -1 && (
          <>
            <div >
              <Header setIsShowAuth={setIsShowAuth} />
              <Banner />
              <Jackpot />
              <SearchGames page={page} setPage={setPage} providerSelect={providerSelect} setProviderSelect={setProviderSelect} search={search} setSearch={setSearch} />
              <Games setIsShowAuth={setIsShowAuth} page={page} setPage={setPage} providerSelect={providerSelect} setProviderSelect={setProviderSelect} search={search} setSearch={setSearch}/>
            </div>

            {
              token && (
                <Route path="/user/" component={() => <SideBar isOpenReplenishment={isOpenReplenishment} setIsOpenReplenishment={setIsOpenReplenishment} />} />
              )
            }

            {
              isShowAuth && !token && <Auth setIsShowAuth={setIsShowAuth} />
            }
            <Replenishment setIsOpenReplenishment={setIsOpenReplenishment} isOpenReplenishment={isOpenReplenishment} mobile />
            <Bonuses setIsOpenReplenishment={setIsOpenReplenishment} setIsShowAuth={setIsShowAuth} />
          </>
        )
      }
      </div>
    </div>
  )
}

export default Mobile;