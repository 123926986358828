/* eslint-disable */
import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGetProviderList } from '../../../store/games/thunk/setGetProviderList';
import { setGetSearchGames } from '../../../store/games/thunk/setGetSearchGames';
import { useRouter } from '../../../components/hook/useRouter';
import { setGetGamesList } from '../../../store/games/thunk/setGetGamesList';
import {translate} from '../../../dictionary';

const SearchGames = ({page, setPage, search, setSearch, providerSelect, setProviderSelect}) => {
  const [isOpenProvider, setIsOpenProvider] = React.useState(false);
  const dispatch = useDispatch();
  const games = useSelector((state) => state.games.search);
  const router = useRouter();

  const navigate = (value) => router.history.push(value);

  React.useEffect(() => {
    dispatch(setGetProviderList())
  }, []);

  const provider = useSelector((state) => state.games.provider);

  return (
    <>
      <div style={{ 
        padding: 10, 
        marginTop: 10, 
        marginBottom: 0, 
        position: 'relative',
        background: 'rgba(255, 255, 255, 0.12)',

        padding: 10,
        margin: 20,
        borderRadius: 24,
        paddingRight: 140
        }}>

        <input type="text" className="searchGame" 
          placeholder={translate('game.search')}
          value={search}
          onChange={(v) => {
            dispatch(setGetGamesList({
              page: 1,
              type: 'slots',
              provider: '',
              search: v.target.value.toLowerCase(),
              is_desktop: 0,
              is_mobile: 1
            }));
            setPage(1);
            setSearch(v.target.value.toLowerCase());
          }}
        />
        <div style={{padding: '0 20px', marginTop: 10, display: 'flex', justifyContent: 'center', position: 'absolute', top: -11, right: -21}}>
          <button onClick={() => setIsOpenProvider(!isOpenProvider)} style={{display: 'flex', alignItems: 'center', columnGap: 8, color: '#FFF', padding: '16px 10px 16px', border: 'none', borderTopRightRadius: 24, borderBottomRightRadius: 24, background: 'rgba(14, 2, 48, 0.3)', }}>
            <span>
              <span>
                <svg width="19" height="19" viewBox="0 0 19 19" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.33563 11.9498C8.55677 11.9498 9.59243 11.1276 9.91076 10.0031L17.5222 10.0031C17.9257 10.0031 18.25 9.67876 18.25 9.27523C18.25 8.8717 17.9257 8.54741 17.5222 8.54741L9.91064 8.54741C9.5926 7.42626 8.56047 6.60071 7.33563 6.60071C6.11078 6.60071 5.07865 7.42626 4.76061 8.54741L1.48137 8.54741C1.07784 8.54741 0.753543 8.8717 0.753543 9.27523C0.753543 9.67876 1.07784 10.0031 1.48137 10.0031L4.76061 10.0031C5.07865 11.1242 6.11078 11.9498 7.33563 11.9498ZM6.11675 9.27523C6.11675 8.60277 6.66316 8.05636 7.33563 8.05636C8.00809 8.05636 8.5545 8.60277 8.5545 9.27523C8.5545 9.9477 8.00809 10.4941 7.33563 10.4941C6.66316 10.4941 6.11675 9.9477 6.11675 9.27523Z" stroke="currentColor" stroke-width="0.5"></path>
                  <path d="M11.3458 6.09905C12.5711 6.09905 13.5997 5.27314 13.9173 4.15235L17.5222 4.15235C17.9257 4.15235 18.25 3.82806 18.25 3.42453C18.25 3.021 17.9257 2.6967 17.5222 2.6967L13.9173 2.6967C13.5993 1.57555 12.5671 0.75 11.3423 0.75C10.1174 0.75 9.08531 1.57555 8.76727 2.6967L1.48137 2.6967C1.07784 2.6967 0.753541 3.021 0.753541 3.42453C0.753541 3.82806 1.07784 4.15235 1.48137 4.15235L8.77081 4.15235C9.08885 5.2735 10.121 6.09905 11.3458 6.09905ZM10.1234 3.42453C10.1234 2.75206 10.6698 2.20565 11.3423 2.20565C12.0148 2.20565 12.5612 2.75206 12.5612 3.42453C12.5612 4.09699 12.0147 4.6434 11.3423 4.6434C10.6702 4.6434 10.1234 4.09384 10.1234 3.42453Z" stroke="currentColor" stroke-width="0.5"></path>
                  <path d="M11.3458 17.7651C12.5709 17.7651 13.6 16.9424 13.9175 15.8184L17.5222 15.8184C17.9257 15.8184 18.25 15.4941 18.25 15.0905C18.25 14.687 17.9257 14.3627 17.5222 14.3627L13.9173 14.3627C13.5993 13.2416 12.5671 12.416 11.3423 12.416C10.1174 12.416 9.08531 13.2416 8.76727 14.3627L1.47783 14.3627C1.0743 14.3627 0.750001 14.687 0.750001 15.0905C0.750001 15.4941 1.0743 15.8184 1.47783 15.8184L8.77081 15.8184C9.08885 16.9395 10.121 17.7651 11.3458 17.7651ZM10.1234 15.0905C10.1234 14.4181 10.6698 13.8717 11.3423 13.8717C12.0148 13.8717 12.5612 14.4181 12.5612 15.0905C12.5612 15.763 12.0147 16.3094 11.3423 16.3094C10.6698 16.3094 10.1234 15.763 10.1234 15.0905Z" stroke="currentColor" stroke-width="0.5"></path>
                </svg>
              </span>
            </span>
            <span>
              {translate('game.provider')}
            </span>
          </button>
        </div>
      </div>
      
      <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', padding: '0 20px', rowGap: 15, marginTop: 15, marginBottom: 15}}>
        {
          isOpenProvider && (
            <>
              <div 
                onClick={() => {
                  dispatch(setGetGamesList({
                    page: 1,
                    type: 'slots',
                    provider: '',
                    search: '',
                    is_desktop: 0,
                    is_mobile: 1
                  }));
                  setIsOpenProvider(false);
                  setSearch('');
                  setProviderSelect('');
                }}
                style={{display: 'flex', alignItems: 'center', columnGap: 8, borderRadius: 8, background: 'rgba(14, 2, 48, 0.3)', color: '#FFF', padding: 10, width: '40%', border: '1px solid rgba(255,255,255,.1)', justifyContent: 'center'}}>
                <span>{translate('game.all')}</span>
              </div>
              {
                provider.map((item) => (
                <div 
                  onClick={() => {
                    dispatch(setGetGamesList({
                      page: 1,
                      type: 'slots',
                      provider: item.provider,
                      search: '',
                      is_desktop: 0,
                      is_mobile: 1
                    }));
                    setIsOpenProvider(false);
                    setSearch('');
                    setProviderSelect(item.provider);
                  }}
                  style={{display: 'flex', alignItems: 'center', columnGap: 8, borderRadius: 8, background: 'rgba(14, 2, 48, 0.3)', color: '#FFF', padding: 10, width: '40%', border: '1px solid rgba(255,255,255,.1)', justifyContent: 'center'}}>
                  <span>{item.provider}</span>
                </div>
                ))
              }
            </>
          )
        }
      </div>
      
      {
        !!games?.list?.length && (
          <div className='games-root'>
            {
              games?.list.map((item) => {
                return (
                  <img key={item.uuid} src={item.image} onClick={() => navigate('/game/' + item.uuid)} alt="" style={{ width: '48%', borderRadius: 12, boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.4)" }} />
                )
              })
            }
            {
              games?.list?.length >= 24 && (
                <Button
                  variant="contained"
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={() => {
                    dispatch(setGetGamesList({
                      page: page + 1,
                      type: 'slots',
                      provider: providerSelect,
                      search: search,
                      is_desktop: 0,
                      is_mobile: 1
                    }));
                    setPage(page + 1)
                  }}
                >{translate('common.more_load')}</Button>
              )
            }

          </div>
        )
      }
    </>
  )
}

export default SearchGames;