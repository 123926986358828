/* eslint-disable */
import React from 'react';
import Confirmation from './confirmation';
import SignIn from './signin';
import SignUp from './signup';
import { useForm } from 'react-hook-form';
import {translate} from '../../../dictionary';
import CloseIcon from '@mui/icons-material/Close';

const Auth = (props) => {
  const [isConfirm, setIsConfirm] = React.useState();
  const [tab, setTab] = React.useState('signin');

  const { register, handleSubmit, control, reset, setValue, watch  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      login: '',
      password: '',
      promocode: '',
      currency: localStorage?.getItem('language') === 'ru' ? 'RUB' : 'KZT'
    }
  });


  const handleChangeTab = (value) => {
    setTab(value)
  }

  if (isConfirm) {
    return (
      <div>
        <Confirmation phone={isConfirm} control={control}/>
      </div>
    )
  }
  
  return (
    <div className='auth-root'>
      <CloseIcon style={{color: '#fff', width: 50, height: 50, padding: 10, position: 'absolute', top: 0, right: 5}}
        onClick={() => {
          props?.setIsShowAuth(false)
        }}
      />
      <div style={{ textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', marginTop: 50 }}>
        <span style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: '#fff', fontWeight: 900, textTransform: 'uppercase', lineHeight: '40px' }}>{translate('signin.modal.registration')}</span>
        <span style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 18, color: '#fff', fontWeight: 900, textTransform: 'uppercase', lineHeight: '20px' }}>{translate('signin.modal.or')}</span>
        <span style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: '#fff', fontWeight: 900, textTransform: 'uppercase', lineHeight: '40px' }}>{translate('signin.modal.login')}</span>
      </div>
      <div style={{ margin: 20, marginTop: 70 }}>
       
          <div className='auth-root__tabs'>
            <div
              className={`auth-root__tabs--tab ${tab === 'signin' ? 'auth-root__tabs--tab--active' : 'auth-root__tabs--tab--disabled'}`}
              onClick={() => handleChangeTab('signin')}
            >
              <span>{translate('signin.login')}</span>
            </div>
            <div
              className={`auth-root__tabs--tab ${tab === 'signup' ? 'auth-root__tabs--tab--active' : 'auth-root__tabs--tab--disabled'}`}
              onClick={() => handleChangeTab('signup')}
            >
              <span>{translate('signin.register')}</span>
            </div>
          </div>
          {
            tab === 'signin' &&  <SignIn tab={tab} setIsShowAuth={props.setIsShowAuth} />
          }
          {
            tab === 'signup' && <SignUp tab={tab} setIsConfirm={setIsConfirm} handleSubmit={handleSubmit} control={control} register={register} reset={reset} setValue={setValue} watch={watch} />
          }
      </div>
    </div>
  )
}

export default Auth;